import { useEffect, useState } from 'react'
import { AppBar, Box, Container, Drawer, IconButton, Toolbar, Typography } from '@mui/material'
import { Menu as MenuIcon, Close as CloseIcon } from '@mui/icons-material'
import { makeStyles } from 'tss-react/mui'
import { User } from '@/components/nav/User'
import { Tasks } from '@/components/nav/Tasks'
import { YourData } from '@/components/nav/YourData'
import { Meetings } from '@/components/meetings/Meetings'
import { PersonalData } from '@/components/PersonalData'
import { Secrets } from '@/components/Secrets'
import { Footer } from '@/components/nav/Footer'
import useWindowSize from '@/helpers/useWindowSize'
import { Events } from 'react-scroll'
import { useAuth } from '@/contexts'
import { useNavigate } from 'react-router-dom'
import { TimeSlots } from '@/components/TimeSlots'
import { CreditsHistory } from '@/components/CreditsHistory'
import { Overview } from '@/components/Overview'
import { Gender } from '@namuho/types'
import { JumpToTopArrow } from '@/components/JumpToTopArrow'

const drawerWidth = 300

const useStyles = makeStyles()((theme) => ({
    root: {
        display: 'flex',
        position: 'relative',
        backgroundColor: theme.palette.background.default,
        paddingBottom: 170, // space for footer
        [theme.breakpoints.down('md')]: {
            paddingBottom: 375,
        },
    },
    appBar: {
        color: theme.palette.primary.contrastText,
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    closeButton: {
        alignSelf: 'flex-end',
        color: theme.palette.primary.contrastText,
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    openButton: {
        color: theme.palette.primary.contrastText,
    },
    drawer: {
        zIndex: theme.zIndex.drawer,
        '& .MuiDrawer-paper': {
            padding: theme.spacing(2),
            width: drawerWidth,
            backgroundColor: theme.palette.secondary.main,
            border: 'none',
        },
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(4),
        flexGrow: 1,
        paddingLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            paddingTop: theme.spacing(8),
            paddingLeft: 0,
            width: '100%',
        },
    },
    overview: {},
    footer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: theme.zIndex.drawer,
    },
    mainContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        alignItems: 'stretch',
        textAlign: 'justify',
        gap: theme.spacing(2),
        paddingTop: theme.spacing(2),
    },
    introText: {
        [theme.breakpoints.down('md')]: {
            fontSize: '1.1rem',
        },
    },
}))

export interface DashboardProps {
    className?: string
}

export function Dashboard(props: DashboardProps) {
    const { classes } = useStyles()
    const { isMobile } = useWindowSize()
    const { user, loading } = useAuth()
    const navigate = useNavigate()
    const [isNavOpen, setIsNavOpen] = useState(false)

    useEffect(() => {
        if (!loading && !user) {
            navigate('/auth/sign-in', { replace: true })
        }
    }, [user, loading, navigate])

    useEffect(() => {
        Events.scrollEvent.register('begin', function () {
            setIsNavOpen(false)
        })
        return () => {
            Events.scrollEvent.remove('begin')
        }
    }, [])

    const handleDrawerToggle = () => {
        setIsNavOpen(!isNavOpen)
    }

    const drawerVariant = isMobile ? 'temporary' : 'permanent'

    return (
        <>
            <Box className={classes.root} {...props}>
                <AppBar color="secondary" className={classes.appBar} position="fixed">
                    <Toolbar>
                        <IconButton aria-label="Menu öffnen" edge="start" onClick={handleDrawerToggle} className={classes.openButton}>
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" noWrap component="h1">
                            Dashboard
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Box component="nav">
                    <Drawer open={isNavOpen} variant={drawerVariant} className={classes.drawer}>
                        <IconButton aria-label="Menu schließen" edge="start" onClick={handleDrawerToggle} className={classes.closeButton}>
                            <CloseIcon />
                        </IconButton>
                        <User />
                        <Tasks />
                        <YourData />
                    </Drawer>
                </Box>
                <Box className={classes.main} component="main">
                    <Container component="section" id="intro" className={classes.mainContainer}>
                        <Typography className={classes.introText} variant="body1">
                            Hey{user?.gender === Gender.Male ? ' lieber' : user?.gender === Gender.Female ? ' liebe' : ''} {user?.firstname || ''},
                            herzlich willkommen in deinem Dashboard!
                        </Typography>
                        <Typography className={classes.introText} variant="body1">
                            Hier bekommst du einen Überblick über all deine bevorstehenden Verabredungen und deiner gesamten Bestellhistorie. Wenn du
                            Änderungen für kommende Verabredungen vornehmen möchtest, bist du hier genau richtig! Keine Sorge, wir übernehmen das
                            Ganze automatisch, allerdings müssen wir darauf hinweisen, dass nachträgliche Änderungen keinen Einfluss auf bereits von
                            uns organisierte Dates oder Treffen haben. Wir geben unser bestes, aber auch wir brauchen etwas Zeit damit alles
                            reibungslos klappt. Wir hoffen auf dein Verständnis.
                        </Typography>
                        <Typography className={classes.introText} variant="body1">
                            Wir wünschen dir geniale Begegnungen mit fabelhaften Menschen!
                        </Typography>
                    </Container>

                    <Meetings />
                    <Secrets />
                    <TimeSlots />
                    <PersonalData />
                    <CreditsHistory />
                    <Overview className={classes.overview} />
                </Box>
                <Footer className={classes.footer} />
            </Box>
            <JumpToTopArrow />
        </>
    )
}
