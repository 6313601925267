import { Box, Paper, Typography } from '@mui/material'
import { CheckBox, ArrowForward } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'
import { MeetingsTypeHeader } from './MeetingsTypeHeader'
import clsx from 'clsx'
import { MeetingType } from '@namuho/types'
import { useReadiness } from '@/hooks/user/useReadiness'
import { useUserMatches } from '@/hooks/user/useUserMatches'
import { useMemo } from 'react'

const useStyles = makeStyles()((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingTop: theme.spacing(1),
    },
    boxLink: {
        '&:hover': {
            textDecoration: 'none',
            boxShadow: theme.shadows[2],
        },
    },
    steps: {
        background: theme.palette.common.white,
        color: theme.palette.common.black,
        padding: theme.spacing(2),
        borderRadius: 0,
    },
    waiting: {
        background: theme.palette.common.white,
        padding: theme.spacing(2),
        borderRadius: 0,
    },
    matched: {
        padding: theme.spacing(2),
        color: theme.palette.common.white,
    },
    tasks: {
        display: 'flex',
        flexDirection: 'column',
        listStyleType: 'none',
        padding: 0,
        gap: theme.spacing(1),
    },
    task: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        padding: theme.spacing(1),
        borderBottom: `1px solid ${theme.palette.common.white}`,
    },
    disabledLink: {},
}))

export const defaultTasks: Tasks = {
    questions: false,
    dates: false,
    purchase: false,
}

export interface Tasks {
    questions: boolean
    dates: boolean
    purchase: boolean
}

export interface MeetingsTypeSectionProps {
    title: string
    dateName: string
    link: string
    meetingType: MeetingType
}

export function MeetingsTypeSection(props: MeetingsTypeSectionProps) {
    const { dateName, link, title, meetingType } = props
    const { classes } = useStyles()
    const { currentMatches, futureMatches, isLoading } = useUserMatches()
    const typeMatches = [...currentMatches, ...futureMatches].filter((match) => match.meetingType === meetingType)

    const { [MeetingType.Romantic]: romantic, [MeetingType.Friendly]: friendly, [MeetingType.Group]: group, personalDetailsAnswered } = useReadiness()
    const dictionary = useMemo(
        () => ({
            [MeetingType.Romantic]: romantic,
            [MeetingType.Friendly]: friendly,
            [MeetingType.Group]: group,
        }),
        [friendly, group, romantic]
    )
    const datesSelected = dictionary[meetingType]?.timeSlotsAvailable
    const slotsBought = dictionary[meetingType]?.slotsBought

    const questionsAnswered = useMemo(() => {
        if (meetingType === MeetingType.Romantic) {
            return (
                dictionary[meetingType]?.weightedAnswered &&
                personalDetailsAnswered &&
                dictionary[meetingType]?.basicAnswered &&
                dictionary[meetingType]?.noGosSeen
            )
        } else {
            return dictionary[meetingType]?.weightedAnswered && dictionary[meetingType]?.basicAnswered
        }
    }, [dictionary, meetingType, personalDetailsAnswered])

    const isWaiting = dictionary[meetingType]?.waiting

    const getLinkParameter = () => {
        if (meetingType === MeetingType.Friendly) {
            if (!dictionary[meetingType]?.basicAnswered) {
                return '?step=BASIC_QUESTIONS'
            }
            if (!dictionary[meetingType]?.weightedAnswered) {
                return '?step=RATED_QUESTIONS'
            }
            if (!dictionary[meetingType]?.slotsBought) {
                return '?step=PRODUCTS'
            }
            if (!dictionary[meetingType]?.timeSlotsAvailable) {
                return '?step=DATES'
            }
        }
        if (meetingType === MeetingType.Romantic) {
            if (!dictionary[meetingType]?.basicAnswered) {
                return '?step=BASIC_QUESTIONS'
            }
            if (!personalDetailsAnswered) {
                return '?step=NOGOS_ME'
            }
            if (!dictionary[meetingType]?.noGosSeen) {
                return '?step=NOGOS_YOU'
            }
            if (!dictionary[meetingType]?.weightedAnswered) {
                return '?step=RATED_QUESTIONS'
            }
            if (!dictionary[meetingType]?.slotsBought) {
                return '?step=PRODUCTS'
            }
            if (!dictionary[meetingType]?.timeSlotsAvailable) {
                return '?step=DATES'
            }
        }
        return ''
    }

    return (
        <Box className={clsx(classes.root)}>
            <Box className="box-link">
                <MeetingsTypeHeader title={title} />
                <div className={classes.container}>
                    {isWaiting === true && !dictionary[meetingType].datesMatched && (
                        <Paper className={classes.waiting}>
                            <Typography variant="h2">Wir bereiten ein passendes Match für dich vor</Typography>
                            <Typography variant="body1">Du bekommst eine E-Mail, sobald wir einen Termin für dich haben.</Typography>
                            <Link className="inline-link" to={link}>
                                Überprüfe deine Daten hier
                            </Link>
                        </Paper>
                    )}
                    {!isWaiting && !dictionary[meetingType].datesMatched && (
                        <Link to={`${link}${getLinkParameter()}`} className={classes.boxLink}>
                            <Paper className={classes.steps}>
                                <Typography variant="h6">
                                    Nur noch wenige Schritte zu deinen nächsten {dateName === 'Romantisch Date' ? 'Dates' : 'Treffen'}
                                </Typography>
                                <ul className={classes.tasks}>
                                    <li className={classes.task}>
                                        {questionsAnswered ? <CheckBox color="primary" /> : <ArrowForward color="primary" />} Beantworte ein paar
                                        Fragen
                                    </li>
                                    <li className={classes.task}>
                                        {slotsBought ? <CheckBox color="primary" /> : <ArrowForward color="primary" />} Wähle dein Paket
                                    </li>
                                    <li className={classes.task}>
                                        {datesSelected ? <CheckBox color="primary" /> : <ArrowForward color="primary" />} Sag uns, wann du verfügbar
                                        bist
                                    </li>
                                </ul>
                            </Paper>
                        </Link>
                    )}
                    {dictionary[meetingType].datesMatched && (
                        <Link to="/dates" className={classes.boxLink}>
                            <Paper className={classes.matched}>
                                <Typography variant="h2">
                                    Dein Abenteuer kann beginnen, wir haben ein {dateName === 'Romantisch Date' ? 'Date' : 'Treffen'} für dich
                                    arrangiert! Schau dir hier die Details an:
                                </Typography>
                                <Typography variant="body1">
                                    {typeMatches[0]?.name || ''}
                                    {typeMatches.length > 1 && ` und ${typeMatches.length - 1} andere`}
                                </Typography>
                            </Paper>
                        </Link>
                    )}
                </div>
            </Box>
        </Box>
    )
}
