import { MeetingType } from '@namuho/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum CreatorSteps {
    WELCOME = 'WELCOME',
    PERSONAL_DATA = 'PERSONAL_DATA',
    CITY = 'CITY',
    EMAIL_CONFIRMATION = 'EMAIL_CONFIRMATION',
    DATE_TYPE = 'DATE_TYPE',
    BASIC_QUESTIONS = 'BASIC_QUESTIONS',
    PERSONAL_DETAILS = 'PERSONAL_DETAILS',
    NOGOS_YOU = 'NOGOS_YOU',
    SECRETS = 'SECRETS',
    RATED_QUESTIONS = 'RATED_QUESTIONS',
    PRODUCTS = 'PRODUCTS',
    DATES = 'DATES',
}

export interface Step {
    id: CreatorSteps
    index: number
    completed: boolean
    accessible: boolean
}

export interface CreatorError {
    error: boolean
    message: string
}

export type Steps = Step[]

export interface Date {
    type: MeetingType
}

export interface CreatorState {
    [MeetingType.Group]: Steps
    [MeetingType.Friendly]: Steps
    [MeetingType.Romantic]: Steps
    activeStepIndex: number
    error: CreatorError
    chosenDateType: MeetingType
    createdUserId: string | undefined
}

const initialState: CreatorState = {
    activeStepIndex: 0,
    chosenDateType: MeetingType.Friendly,
    createdUserId: undefined,
    error: {
        error: false,
        message: '',
    },
    [MeetingType.Romantic]: [
        {
            id: CreatorSteps.WELCOME,
            index: 0,
            completed: false,
            accessible: true,
        },
        {
            id: CreatorSteps.PERSONAL_DATA,
            index: 1,
            completed: false,
            accessible: false,
        },
        {
            id: CreatorSteps.EMAIL_CONFIRMATION,
            index: 2,
            completed: false,
            accessible: false,
        },
        {
            id: CreatorSteps.CITY,
            index: 3,
            completed: false,
            accessible: false,
        },
        {
            id: CreatorSteps.DATE_TYPE,
            index: 4,
            completed: false,
            accessible: false,
        },
        {
            id: CreatorSteps.BASIC_QUESTIONS,
            index: 5,
            completed: false,
            accessible: false,
        },
        {
            id: CreatorSteps.PERSONAL_DETAILS,
            index: 6,
            completed: false,
            accessible: false,
        },
        {
            id: CreatorSteps.NOGOS_YOU,
            index: 7,
            completed: false,
            accessible: false,
        },
        {
            id: CreatorSteps.RATED_QUESTIONS,
            index: 8,
            completed: false,
            accessible: false,
        },
        {
            id: CreatorSteps.SECRETS,
            index: 9,
            completed: false,
            accessible: false,
        },
        {
            id: CreatorSteps.PRODUCTS,
            index: 10,
            completed: false,
            accessible: false,
        },
        {
            id: CreatorSteps.DATES,
            index: 11,
            completed: false,
            accessible: false,
        },
    ],
    [MeetingType.Friendly]: [
        {
            id: CreatorSteps.WELCOME,
            index: 0,
            completed: false,
            accessible: true,
        },
        {
            id: CreatorSteps.PERSONAL_DATA,
            index: 1,
            completed: false,
            accessible: false,
        },
        {
            id: CreatorSteps.EMAIL_CONFIRMATION,
            index: 2,
            completed: false,
            accessible: false,
        },
        {
            id: CreatorSteps.CITY,
            index: 3,
            completed: false,
            accessible: false,
        },
        {
            id: CreatorSteps.DATE_TYPE,
            index: 4,
            completed: false,
            accessible: false,
        },

        {
            id: CreatorSteps.BASIC_QUESTIONS,
            index: 5,
            completed: false,
            accessible: false,
        },
        {
            id: CreatorSteps.RATED_QUESTIONS,
            index: 6,
            completed: false,
            accessible: false,
        },
        {
            id: CreatorSteps.SECRETS,
            index: 7,
            completed: false,
            accessible: false,
        },
        {
            id: CreatorSteps.PRODUCTS,
            index: 8,
            completed: false,
            accessible: false,
        },
        {
            id: CreatorSteps.DATES,
            index: 9,
            completed: false,
            accessible: false,
        },
    ],
    [MeetingType.Group]: [
        {
            id: CreatorSteps.WELCOME,
            index: 0,
            completed: false,
            accessible: true,
        },
        {
            id: CreatorSteps.PERSONAL_DATA,
            index: 1,
            completed: false,
            accessible: false,
        },
        {
            id: CreatorSteps.EMAIL_CONFIRMATION,
            index: 2,
            completed: false,
            accessible: false,
        },
        {
            id: CreatorSteps.CITY,
            index: 3,
            completed: false,
            accessible: false,
        },
        {
            id: CreatorSteps.DATE_TYPE,
            index: 4,
            completed: false,
            accessible: false,
        },

        {
            id: CreatorSteps.BASIC_QUESTIONS,
            index: 5,
            completed: false,
            accessible: false,
        },
        {
            id: CreatorSteps.RATED_QUESTIONS,
            index: 6,
            completed: false,
            accessible: false,
        },
        {
            id: CreatorSteps.PRODUCTS,
            index: 7,
            completed: false,
            accessible: false,
        },
        {
            id: CreatorSteps.DATES,
            index: 8,
            completed: false,
            accessible: false,
        },
    ],
}

export const creatorSlice = createSlice({
    name: 'creator',
    initialState,
    reducers: {
        nextStep: (state) => {
            if (state.error.error) {
                return state
            }
            const steps = state[state.chosenDateType]
            if (state.activeStepIndex === steps.length - 1) {
                return state
            }
            window.goatcounter.count({
                path: 'mainStepper/nextStep',
                title: `navigate to next step ${state.activeStepIndex + 1} path ${state.chosenDateType}}`,
                event: true,
            })
            window.scrollTo(0, 0)
            state.activeStepIndex = state.activeStepIndex + 1
        },
        previousStep: (state) => {
            if (state.error.error) {
                return state
            }
            if (state.activeStepIndex === 0) {
                state.activeStepIndex = 0
            }
            window.goatcounter.count({
                path: 'mainStepper/previousStep',
                title: `navigate to previous step ${state.activeStepIndex - 1} path ${state.chosenDateType}}`,
                event: true,
            })
            window.scrollTo(0, 0)
            state.activeStepIndex = state.activeStepIndex - 1
        },
        resetSteps: () => {
            return initialState
        },
        resetError: (state) => {
            state.error = {
                error: false,
                message: '',
            }
        },
        goToPaymentStep: (state) => {
            const steps = state[state.chosenDateType]
            const paymentStep = steps.find((step) => step.id === CreatorSteps.PRODUCTS)?.index || undefined
            if (paymentStep) {
                state.activeStepIndex = paymentStep
                window.scrollTo(0, 0)
            }
        },
        goToStepNumber: (state, action: PayloadAction<number>) => {
            state.activeStepIndex = action.payload
            window.goatcounter.count({
                path: 'mainStepper/goToStepNumber',
                title: `navigate to step by number ${action.payload} path ${state.chosenDateType}}`,
                event: true,
            })
            window.scrollTo(0, 0)
        },
        markStepCompleted: (state, action: PayloadAction<number>) => {
            const steps = state[state.chosenDateType]
            if (action.payload < 3) {
                state[MeetingType.Friendly][action.payload].completed = true
                state[MeetingType.Romantic][action.payload].completed = true
                state[MeetingType.Group][action.payload].completed = true
            }
            steps[action.payload].completed = true
        },
        markStepCompletedByName: (state, action: PayloadAction<CreatorSteps>) => {
            const steps = state[state.chosenDateType]
            if (action.payload === CreatorSteps.CITY) {
                const friendlyCityStep = state[MeetingType.Friendly].find((step) => step.id === action.payload)
                const romanticCityStep = state[MeetingType.Romantic].find((step) => step.id === action.payload)
                const groupCityStep = state[MeetingType.Group].find((step) => step.id === action.payload)
                if (friendlyCityStep) {
                    friendlyCityStep.completed = true
                }
                if (romanticCityStep) {
                    romanticCityStep.completed = true
                }
                if (groupCityStep) {
                    groupCityStep.completed = true
                }
            } else {
                const affectedStep = steps.find((step) => step.id === action.payload)
                if (affectedStep) {
                    affectedStep.completed = true
                }
            }
        },
        markStepsAccessible: (state, action: PayloadAction<number[]>) => {
            const steps = state[state.chosenDateType]
            steps.map((step) => {
                if (action.payload.includes(step.index)) {
                    step.accessible = true
                }
                return step
            })
        },
        markStepInaccessible: (state, action: PayloadAction<number>) => {
            const steps = state[state.chosenDateType]
            steps[action.payload].accessible = false
        },
        getBackToSecondStep: (state) => {
            state[MeetingType.Friendly][0].accessible = true
            state[MeetingType.Friendly][1].accessible = true
            state[MeetingType.Friendly][1].completed = false
            state[MeetingType.Romantic][0].accessible = true
            state[MeetingType.Romantic][1].accessible = true
            state[MeetingType.Romantic][1].completed = false
            state[MeetingType.Group][0].accessible = true
            state[MeetingType.Group][1].accessible = true
            state[MeetingType.Group][1].completed = false
            state.activeStepIndex = 1
            window.scrollTo(0, 0)
        },
        setError: (state, action: PayloadAction<CreatorError>) => {
            state.error = action.payload
        },
        setChosenDateType: (state, action: PayloadAction<MeetingType>) => {
            // check if payload is valid MeetingType
            if (Object.values(MeetingType).includes(action.payload)) {
                state.chosenDateType = action.payload
            }
        },
        setUserId: (state, action: PayloadAction<string>) => {
            state.createdUserId = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    nextStep,
    previousStep,
    getBackToSecondStep,
    goToStepNumber,
    goToPaymentStep,
    markStepCompleted,
    markStepCompletedByName,
    markStepsAccessible,
    markStepInaccessible,
    resetSteps,
    resetError,
    setError,
    setChosenDateType,
    setUserId,
} = creatorSlice.actions

export default creatorSlice.reducer
