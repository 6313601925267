import Tooltip from '@mui/material/Tooltip'
import { SliderValueLabelProps } from '@mui/material/Slider'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    arrow: {
        '&::before': {
            backgroundColor: theme.palette.primary.main,
        },
    },
}))

export interface SliderTooltipProps extends SliderValueLabelProps {
    labelSuffix: string
    disabled?: boolean
}

export const SliderTooltip = (props: SliderTooltipProps) => {
    const { children, value, labelSuffix, disabled } = props
    const { classes } = useStyles()

    return (
        <Tooltip
            classes={{ tooltip: classes.root, arrow: classes.arrow }}
            arrow
            open={!disabled}
            enterTouchDelay={100}
            placement="top"
            title={`${value} ${labelSuffix}`}
        >
            {children}
        </Tooltip>
    )
}
