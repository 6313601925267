import { StepIconProps } from '@mui/material'
import { styled } from '@mui/material/styles'

const StepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean }
}>(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.common.white,
    zIndex: 1,
    color: theme.palette.common.black,
    width: 33,
    height: 33,
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    }),
    ...(ownerState.completed && {
        border: `2px solid ${theme.palette.primary.main}`,
    }),
}))

export const StepIcon = (props: StepIconProps) => {
    const { active, completed, icon } = props

    return <StepIconRoot ownerState={{ completed, active }}>{icon}</StepIconRoot>
}
