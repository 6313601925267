import { useState } from 'react'

import { useEventListener, useIsomorphicLayoutEffect } from 'usehooks-ts'

interface WindowSize {
    width: number
    height: number
}

interface UseWindowSizeInterface {
    windowSize: WindowSize
    isDesktop: boolean
    isTablet: boolean
    isMobile: boolean
}

const DESKTOP = 1200 // theme breakpoint lg
const TABLET = 900 // theme breakpoint md

function useWindowSize(): UseWindowSizeInterface {
    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: 0,
        height: 0,
    })

    const isDesktop = windowSize.width >= DESKTOP
    const isTablet = windowSize.width >= TABLET && windowSize.width < DESKTOP
    const isMobile = windowSize.width < TABLET

    const handleSize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        })
    }

    useEventListener('resize', handleSize)

    // Set size at the first client-side load
    useIsomorphicLayoutEffect(() => {
        handleSize()
    }, [])

    return { windowSize, isDesktop, isTablet, isMobile }
}

export default useWindowSize
