import { useAuth } from '@/contexts'
import { Typography } from '@mui/material'
import { IMatch, IMessage } from '@namuho/types'
import clsx from 'clsx'
import { makeStyles } from 'tss-react/mui'
import Linkify from 'linkify-react'

const useStyles = makeStyles()((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '80%',
        justifyContent: 'space-between',
        gap: theme.spacing(1),
        margin: theme.spacing(2),
        border: `1px solid ${theme.palette.primary.main}`,
        padding: theme.spacing(1),
        alignSelf: 'flex-end',
        overflowWrap: 'break-word',
    },
    theirs: {
        alignSelf: 'flex-start',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    date: {
        fontSize: '0.8rem',
        lineHeight: '1rem',
        color: theme.palette.grey[500],
    },
}))

export interface ChatMessageProps {
    match: IMatch
    message: IMessage
}

export const ChatMessage = (props: ChatMessageProps) => {
    const { message, match } = props
    const { user } = useAuth()
    const isTheirs = message?._metadata.createdBy !== user?.id
    const { classes } = useStyles()
    return (
        <div className={clsx(classes.root, isTheirs && classes.theirs)}>
            <Typography variant="body2">
                <Linkify options={{ target: '_blank' }}>{message.content}</Linkify>
            </Typography>
            <Typography className={classes.date} variant="caption">
                {new Date(message._metadata.createdAt).toLocaleString('de-DE', { dateStyle: 'short', timeStyle: 'short' })}
            </Typography>
        </div>
    )
}
