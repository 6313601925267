import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

type ClassKeys = void | 'root'

const useStyles = makeStyles<ClassKeys>()((theme) => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        padding: theme.spacing(1),
        fontSize: '1.5rem',
    },
}))

export interface MeetingsTypeHeaderProps {
    title: string
}

export function MeetingsTypeHeader(props: MeetingsTypeHeaderProps) {
    const { title } = props
    const { classes } = useStyles()
    return (
        <Typography className={classes.root} variant="h1">
            {title}
        </Typography>
    )
}
