import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { NavHeadline } from './NavHeadline'

type ClassKeys = void | 'root'

const useStyles = makeStyles<ClassKeys>()((theme) => ({
    root: {
        marginBottom: theme.spacing(4),
    },
    links: {
        display: 'flex',
        flexDirection: 'column',
    },
}))

export interface NavSectionProps {
    title: string
    children?: React.ReactNode
}

export function NavSection(props: NavSectionProps) {
    const { children, title } = props
    const { classes } = useStyles()

    return (
        <div className={classes.root}>
            <NavHeadline>{title}</NavHeadline>
            <div className={classes.links}>{children}</div>
        </div>
    )
}
