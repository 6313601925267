import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
    root: {
        textAlign: 'left',
        marginBottom: theme.spacing(2),
    },
}))

export interface StepTitleProps {
    title?: string
}

export function StepTitle(props: StepTitleProps) {
    const { title } = props
    const { classes } = useStyles()
    if (!title) {
        return null
    }
    return (
        <Typography className={classes.root} variant="h2">
            {title}
        </Typography>
    )
}
