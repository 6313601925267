import { forwardRef } from 'react'
import clsx from 'clsx'
import { makeStyles } from 'tss-react/mui'

export interface RadioInputOption {
    value: string
    label: string
    disabled?: boolean
}

export interface RadioInputProps {
    name: string
    options: RadioInputOption[]
    label?: string
    error?: string
    className?: string
    rated?: boolean
    centered?: boolean
}

const useStyles = makeStyles<RadioInputProps>()((theme, props) => ({
    root: {
        display: 'grid',
        gridTemplateColumns: props.centered || props.rated ? '3fr 3fr' : '1fr 3fr',
        border: 'none',
        gap: theme.spacing(2),
        width: '100%',
        padding: 0,
        margin: 0,
        minWidth: 0,
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: theme.spacing(2),
            gap: theme.spacing(1),
        },
    },
    label: {
        width: '100%',
        textAlign: props.centered ? 'center' : 'left',
        display: 'flex',
        alignItems: 'center',
        justifyContent: props.centered ? 'center' : 'start',
        padding: 0,
    },
    ratedLabel: {
        fontSize: theme.typography.pxToRem(12),
        fontWeight: theme.typography.fontWeightBold,
    },
    input: {
        display: 'flex',
        gap: theme.spacing(2),
        justifyContent: props.centered ? 'center' : props.rated ? 'space-between' : 'start',
        flexWrap: 'wrap',
        padding: theme.spacing(1),
        flexGrow: 2,
        '&:focus': {
            borderRadius: 0,
        },
        '& label': {
            marginBottom: 0,
        },
        [theme.breakpoints.down('md')]: {
            justifyContent: 'start',
        },
    },
    singleOption: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'left',
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(2),
        gap: theme.spacing(2),
        maxWidth: '100%',
    },
    singleLabel: {
        maxWidth: '100%',
        overflowWrap: 'break-word',
        hyphens: 'auto',
        [theme.breakpoints.down('md')]: {
            fontSize: '1rem',
        },
    },
    inputWrapper: {
        width: 25,
    },
    disabledOption: {
        position: 'relative',
        cursor: 'none',
        opacity: 0.7,
        '&::after': {
            position: 'absolute',
            padding: 5,
            fontSize: 14,
            display: 'block',
            top: 20,
            left: 100,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,

            content: "'Demnächst verfügbar'",
            transform: 'rotate(25deg)',
        },
    },
}))

export const RadioInput = forwardRef<HTMLInputElement, RadioInputProps>((props: RadioInputProps, ref) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { name, label, options, error, rated, centered = false, ...rest } = props
    const { classes } = useStyles(props)
    return (
        <fieldset className={classes.root}>
            {label && <span className={clsx(classes.label, rated && classes.ratedLabel)}>{label}</span>}
            <div className={classes.input} {...rest}>
                {options.map((option) => (
                    <label
                        className={clsx(classes.singleOption, option.disabled && classes.disabledOption)}
                        key={option.value}
                        htmlFor={`${name}-${option.label}`}
                    >
                        <div className={classes.inputWrapper}>
                            <input
                                name={name}
                                id={`${name}-${option.label}`}
                                type="radio"
                                disabled={option.disabled}
                                value={option.value}
                                ref={ref}
                                {...rest}
                            ></input>
                        </div>
                        <span className={classes.singleLabel}>{option.label}</span>
                    </label>
                ))}
            </div>
            {error && <span>{error}</span>}
        </fieldset>
    )
})

RadioInput.displayName = 'RadioInput'
