import { Container } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { MeetingsTypeSection } from './MeetingsTypeSection'
import { MeetingsSkeleton } from '@/skeletons/MeetingsSkeleton'
import { useReadiness } from '@/hooks/user/useReadiness'
import { MeetingType } from '@namuho/types'

type ClassKeys = void | 'root'

const useStyles = makeStyles<ClassKeys>()((theme) => ({
    root: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: theme.spacing(2),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
}))

export function Meetings() {
    const { classes } = useStyles()
    const { isLoading } = useReadiness()

    return (
        <Container className={classes.root} component="section" id="meetings">
            {isLoading && <MeetingsSkeleton />}
            {!isLoading && (
                <>
                    <MeetingsTypeSection
                        meetingType={MeetingType.Romantic}
                        title="Bevorstehende Dates"
                        link="go/romantic"
                        dateName="Romantisch Date"
                    ></MeetingsTypeSection>

                    <MeetingsTypeSection
                        meetingType={MeetingType.Friendly}
                        title="Bevorstehende Treffen"
                        link="go/friends"
                        dateName="Freundschaftlich Treffen"
                    ></MeetingsTypeSection>

                    <MeetingsTypeSection
                        meetingType={MeetingType.Group}
                        title="Bevorstehende Gruppentreffen"
                        link="go/group"
                        dateName="Gruppentreffen"
                    ></MeetingsTypeSection>
                </>
            )}
        </Container>
    )
}
