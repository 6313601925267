export const ageMarks = [
    {
        value: 18,
        label: '18',
    },
    { value: 35, label: '35' },
    { value: 50, label: '50' },
    { value: 65, label: '65' },
    { value: 80, label: '80' },
    { value: 99, label: '99' },
]
