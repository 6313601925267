import { useEffect, useState } from 'react'
import { AppBar, Box, Button, Container, Drawer, IconButton, Paper, Toolbar, Typography } from '@mui/material'
import { Menu as MenuIcon, Close as CloseIcon, ChevronLeft as ChevronLeftIcon } from '@mui/icons-material'
import { makeStyles } from 'tss-react/mui'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { User } from '@/components/nav/User'
import { Footer } from '@/components/nav/Footer'
import useWindowSize from '@/helpers/useWindowSize'
import { NavSection } from '@/components/nav/NavSection'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'
import { NavDateInfo } from '@/components/dates/NavDateInfo'
import { MainDateInfo } from '@/components/dates/MainDateInfo'
import { setActiveDate } from '@/slices/meetings'
import { Chat } from '@/components/chat/Chat'
import { useAuth } from '@/contexts'
import { ConsumableMatch, useUserMatches } from '@/hooks/user/useUserMatches'
import { IMatch } from '@namuho/types'
import { CatchError } from '@/components/CatchError'
import clsx from 'clsx'

const drawerWidth = 300

const useStyles = makeStyles()((theme) => ({
    root: {
        display: 'flex',
        position: 'relative',
        backgroundColor: theme.palette.background.default,
    },
    appBar: {
        color: theme.palette.primary.contrastText,
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    closeButton: {
        alignSelf: 'flex-end',
        color: theme.palette.primary.contrastText,
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    openButton: {
        color: theme.palette.primary.contrastText,
    },
    dashboardLink: {
        justifyContent: 'start',
    },
    drawer: {
        zIndex: theme.zIndex.drawer,
        '& .MuiDrawer-paper': {
            padding: theme.spacing(2),
            width: drawerWidth,
            backgroundColor: theme.palette.secondary.main,
            border: 'none',
            paddingBottom: 250,
        },
    },
    main: {
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        aligntems: 'stretch',
        paddingBottom: theme.spacing(2),
        paddingLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        flexGrow: 1,
        [theme.breakpoints.down('md')]: {
            paddingTop: theme.spacing(8),
            paddingLeft: 0,
            width: '100%',
        },
    },
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'stretch',
        width: '100%',
        minHeight: '85vh',
        height: '100%',
        paddingTop: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            paddingBottom: theme.spacing(12),
        },
    },
    inactiveMessage: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: theme.palette.primary.contrastText,
        padding: theme.spacing(4),
        zIndex: theme.zIndex.drawer + 1,
        pointerEvents: 'auto',
    },
    inactive: {
        opacity: 0.6,
        pointerEvents: 'none',
    },
}))

export interface DatesProps {
    className?: string
}

export function Dates(props: DatesProps) {
    const { classes } = useStyles()
    const { isMobile } = useWindowSize()
    const [isNavOpen, setIsNavOpen] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const { user, loading } = useAuth()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { currentMatches, futureMatches, pastMatches, matches, isLoading } = useUserMatches()
    const activeMatches = [...currentMatches, ...futureMatches]

    const activeDateId = useSelector((state: RootState) => state.meetings.activeDate)
    const activeDate = matches.find((match: IMatch) => match.id === activeDateId) || matches[0]
    useEffect(() => {
        if (!loading && !user) {
            navigate('/auth/sign-in', { replace: true })
        }
    }, [user, loading, navigate])
    const activeDateIdSearchParam = searchParams.get('activeDateId')

    useEffect(() => {
        if (activeDateIdSearchParam) {
            dispatch(setActiveDate(activeDateIdSearchParam))
            setSearchParams([])
        }
    }, [activeDateId, activeDateIdSearchParam, dispatch, setSearchParams])

    useEffect(() => {
        if (!activeDateId && !activeDateIdSearchParam && matches.length > 0) {
            dispatch(setActiveDate(matches[0].id))
        }
    }, [activeDateId, activeDateIdSearchParam, activeMatches, dispatch, matches])

    const handleDrawerToggle = () => {
        setIsNavOpen(!isNavOpen)
    }

    const handleDateSelect = (id: string) => {
        setIsNavOpen(false)
        dispatch(setActiveDate(id))
    }

    const drawerVariant = isMobile ? 'temporary' : 'permanent'

    return (
        <CatchError section="dates">
            <Box className={classes.root} {...props}>
                <AppBar color="secondary" className={classes.appBar} position="fixed">
                    <Toolbar>
                        <IconButton aria-label="Menu öffnen" edge="start" onClick={handleDrawerToggle} className={classes.openButton}>
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" noWrap component="h1">
                            Dates
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Box component="nav">
                    <Drawer open={isNavOpen} variant={drawerVariant} className={classes.drawer}>
                        <IconButton aria-label="Menu schließen" edge="start" onClick={handleDrawerToggle} className={classes.closeButton}>
                            <CloseIcon />
                        </IconButton>
                        <User isNotDashbaord={true} />
                        <NavSection title="Aktive Dates">
                            {activeMatches.map((match: ConsumableMatch) => (
                                <>
                                    <NavDateInfo
                                        key={match.id}
                                        onSelect={() => handleDateSelect(match.id)}
                                        active={activeDateId === match.id}
                                        meeting={match}
                                    />
                                </>
                            ))}
                            {activeMatches.length === 0 && <Typography variant="body1">Noch keine matches...</Typography>}
                        </NavSection>
                        <NavSection title="Vorherige Dates">
                            {pastMatches.map((match: ConsumableMatch) => (
                                <NavDateInfo
                                    key={match.id}
                                    onSelect={() => handleDateSelect(match.id)}
                                    active={activeDateId === match.id}
                                    meeting={match}
                                />
                            ))}
                            {pastMatches.length === 0 && <Typography variant="body1">Noch keine matches hier...</Typography>}
                        </NavSection>
                    </Drawer>
                </Box>
                <Box className={clsx(classes.main, matches.length === 0 && classes.inactive)} component="main">
                    {activeDate && <MainDateInfo meeting={activeDate} />}
                    <Container component="section" className={classes.mainContainer}>
                        <Chat />
                    </Container>
                </Box>
                {matches.length === 0 && !isLoading && (
                    <Paper className={classes.inactiveMessage} elevation={16}>
                        <Typography variant="h1" component="h2">
                            Hier kannst du deine Matches sehen und mit ihnen sprechen, um zum Beispiel den Ort zu vereinbaren, an dem ihr euch treffen
                            werdet.
                        </Typography>
                        <Typography variant="body1">Du hast noch keine matches vorbereitet</Typography>
                        <Button className={classes.dashboardLink} variant="text" startIcon={<ChevronLeftIcon />} component={Link} to="/">
                            Dashboard
                        </Button>
                    </Paper>
                )}
            </Box>
            <Footer />
        </CatchError>
    )
}
