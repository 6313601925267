import { makeStyles } from 'tss-react/mui'
import { Slider, SliderProps } from '@mui/material'
import { SliderTooltip } from './SliderTooltip'
import { heightMarks } from '@/helpers/heightMarks'
import useWindowSize from '@/helpers/useWindowSize'

export interface HeightInputProps extends SliderProps {
    handleChange: (value: number | number[]) => void
}
const useStyles = makeStyles()((theme) => ({
    markLabel: {
        fontSize: '0.9rem',
        color: 'silver',
    },
}))

export const HeightInput = (props: HeightInputProps) => {
    const { handleChange, value, disabled } = props
    const { classes } = useStyles()
    const { isMobile } = useWindowSize()
    return (
        <Slider
            onChange={(_, value) => {
                handleChange(value)
            }}
            disabled={disabled}
            value={value || 120}
            valueLabelDisplay={isMobile ? 'auto' : 'on'}
            classes={{ markLabel: classes.markLabel }}
            slots={
                isMobile
                    ? undefined
                    : {
                          valueLabel: (props) => <SliderTooltip {...props} disabled={disabled} labelSuffix="cm" />,
                      }
            }
            marks={heightMarks}
            min={120}
            max={220}
            step={1}
        />
    )
}
