import { StepContent } from './StepContent'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'
import { CreatorButtons } from './CreatorButtons'
import { CreatorSteps, markStepCompletedByName, nextStep } from '@/slices/creator'
import { ProductsBuy } from '../common/ProductsBuy'

export const Products = () => {
    const chosenDateType = useSelector((state: RootState) => state.creator.chosenDateType)
    const dispatch = useDispatch()

    const handleNext = () => {
        dispatch(nextStep())
    }

    const handleBought = () => {
        dispatch(markStepCompletedByName(CreatorSteps.PRODUCTS))
    }

    return (
        <StepContent
            content={<ProductsBuy dateType={chosenDateType} onBought={handleBought} />}
            variant="transparent"
            buttons={
                <CreatorButtons
                    nextStepActive={true}
                    previousStepActive={true}
                    isFirst={false}
                    isLast={false}
                    skipActive={true}
                    handleNext={handleNext}
                />
            }
        />
    )
}
