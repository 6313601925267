import { useDispatch } from 'react-redux'
import { makeStyles } from 'tss-react/mui'
import { StepContent } from './StepContent'
import { markStepCompleted, nextStep, setChosenDateType } from '@/slices/creator'
import { Button, Typography } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'
import { MeetingType } from '@namuho/types'

const useStyles = makeStyles()((theme) => ({
    root: {
        maxWidth: 800,
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: theme.spacing(2),
    },
}))

export const Welcome = () => {
    const { classes } = useStyles()
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()

    const meetingTypeSearchParam = searchParams.get('meetingType')

    useEffect(() => {
        if (meetingTypeSearchParam) {
            try {
                dispatch(setChosenDateType(meetingTypeSearchParam as MeetingType))
            } catch (e) {
                console.error(e)
            }
        }
    }, [dispatch, meetingTypeSearchParam])

    const handleNext = () => {
        dispatch(markStepCompleted(0))
        dispatch(nextStep())
    }
    return (
        <StepContent
            content={
                <div className={classes.root}>
                    <Typography variant="h2">Der einfachste Weg, Menschen kennenzulernen</Typography>
                    <Typography variant="h1">
                        Du bist nur wenige Schritte davon entfernt, mit deinen ersten{' '}
                        {meetingTypeSearchParam === MeetingType.Romantic
                            ? 'Dates'
                            : meetingTypeSearchParam === MeetingType.Friendly
                            ? 'Treffen'
                            : 'Dates oder Treffen'}{' '}
                        loszulegen!
                    </Typography>
                    <Typography variant="body1">
                        Richte jetzt dein kostenloses Konto ein und beantworte ein paar Fragen, wir finden dann das beste Match für dich
                    </Typography>
                    <Button variant="contained" onClick={handleNext}>
                        Los geht's
                    </Button>
                </div>
            }
            buttons={<></>}
        />
    )
}
