import { useEffect } from 'react'
import { Box, Step, StepLabel, Stepper, Tooltip } from '@mui/material'
import { stepLabelClasses } from '@mui/material/StepLabel'
import type { RootState } from '@/store'
import { useSelector, useDispatch } from 'react-redux'
import {
    CreatorSteps,
    goToPaymentStep,
    goToStepNumber,
    markStepCompleted,
    markStepCompletedByName,
    markStepsAccessible,
    setChosenDateType,
} from '@/slices/creator'
import { PersonalDataForm } from './PersonalDataForm'
import { ConfirmEmailForm } from './ConfirmEmailForm'
import { DateType } from './DateType'
import { Secrets } from './Secrets'
import { BasicQuestions } from './BasicQuestions'
import { PersonalDetails } from './PersonalDetails'
import { RatedQuestions } from './RatedQuestions'
import { NoGosAnswers } from './NoGosAnswers'
import { Dates } from './Dates'
import { Products } from './Products'
import { makeStyles } from 'tss-react/mui'
import useWindowSize from '@/helpers/useWindowSize'
import { useAuth } from '@/contexts'
import { StepConnectorNamuho } from '../common/stepper/StepConnectorNamuho'
import { StepIcon } from '../common/stepper/StepIcon'
import { MeetingType } from '@namuho/types'
import { useSearchParams } from 'react-router-dom'
import { useReadiness } from '@/hooks/user/useReadiness'
import { Welcome } from './Welcome'
import { City } from './City'
import { Logo } from '../Logo'

const useStyles = makeStyles()((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
        },
    },
    stepper: {},
    step: {
        padding: 0,
    },
    stepLabel: {
        padding: 0,
        [`& .${stepLabelClasses.iconContainer}`]: {
            paddingRight: 0,
        },
    },
    stepLabelInactive: {
        opacity: 0.5,
    },
    button: {},
    content: {
        overflow: 'hidden',
        maxWidth: '100%',
    },
    logo: {
        color: theme.palette.primary.main,
        width: 300,
        [theme.breakpoints.down('md')]: {
            background: `rgb(255, 255, 255, 0.8)`,
            padding: theme.spacing(2),
        },
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.spacing(4),
    },
}))

export function Creator() {
    const { user } = useAuth()
    const [searchParams, setSearchParams] = useSearchParams()
    const activeStepIndex = useSelector((state: RootState) => state.creator.activeStepIndex)
    const activeStep = useSelector((state: RootState) => {
        const index = state.creator.activeStepIndex
        const chosenPath = state.creator.chosenDateType
        return state.creator[chosenPath].find((step) => step.index === index)?.id || CreatorSteps.WELCOME
    })
    const steps = useSelector((state: RootState) => {
        const chosenPath = state.creator.chosenDateType
        return state.creator[chosenPath]
    })
    const dateType = useSelector((state: RootState) => state.creator.chosenDateType)
    const { [MeetingType.Romantic]: romantic, [MeetingType.Friendly]: friendly, [MeetingType.Group]: group, personalDetailsAnswered } = useReadiness()

    const dispatch = useDispatch()
    const { cx, classes } = useStyles()
    const { isMobile } = useWindowSize()

    const handleStep = (stepIndex: number) => () => {
        if (steps[stepIndex].accessible) {
            dispatch(goToStepNumber(stepIndex))
        } else {
            return
        }
    }

    useEffect(() => {
        const dictionary = {
            [MeetingType.Romantic]: romantic,
            [MeetingType.Friendly]: friendly,
            [MeetingType.Group]: group,
        }
        const readiness = dictionary[dateType]
        if (readiness?.basicAnswered) {
            dispatch(markStepCompletedByName(CreatorSteps.BASIC_QUESTIONS))
        }
        if (personalDetailsAnswered) {
            dispatch(markStepCompletedByName(CreatorSteps.PERSONAL_DETAILS))
        }
        if (readiness?.weightedAnswered) {
            dispatch(markStepCompletedByName(CreatorSteps.RATED_QUESTIONS))
        }
        if (readiness?.secretsAnswered) {
            dispatch(markStepCompletedByName(CreatorSteps.SECRETS))
        }
        if (readiness?.noGosSeen) {
            dispatch(markStepCompletedByName(CreatorSteps.NOGOS_YOU))
        }
        if (readiness?.slotsBought) {
            dispatch(markStepCompletedByName(CreatorSteps.PRODUCTS))
        }
        if (readiness?.timeSlotsAvailable) {
            dispatch(markStepCompletedByName(CreatorSteps.DATES))
        }
    }, [activeStepIndex, dateType, dispatch, friendly, group, personalDetailsAnswered, romantic])

    useEffect(() => {
        if (user) {
            dispatch(markStepCompleted(0))
            dispatch(markStepCompleted(1))
            dispatch(markStepCompleted(2))
            dispatch(markStepsAccessible([3, 4, 5, 6, 7, 8, 9, 10]))
            if (searchParams.get('meetingType')) {
                dispatch(setChosenDateType(searchParams.get('meetingType') as MeetingType))
            }
            if (searchParams.get('paymentCallback')) {
                dispatch(goToPaymentStep())
            } else {
                dispatch(goToStepNumber(3))
            }
        } else {
            markStepsAccessible([0])
        }
    }, [dispatch, searchParams, setSearchParams, user])

    const stepperType = isMobile ? 'vertical' : 'horizontal'

    return (
        <div className={classes.wrapper}>
            {activeStepIndex === 0 && <Logo className={classes.logo} />}
            <Box className={classes.root}>
                <Stepper
                    className={classes.stepper}
                    orientation={stepperType}
                    nonLinear
                    activeStep={activeStepIndex}
                    connector={<StepConnectorNamuho />}
                >
                    {steps.map((step) => {
                        return (
                            <Step completed={steps[step.index].completed} key={(step.index + 1).toString()} className={classes.step}>
                                {user && step.index < 3 && (
                                    <Tooltip
                                        title="In den ersten 3 Schritten hast du ein Konto eingerichtet, du brauchst sie nicht mehr."
                                        placement="top"
                                    >
                                        <StepLabel
                                            className={cx(classes.stepLabel, classes.stepLabelInactive)}
                                            StepIconComponent={StepIcon}
                                        ></StepLabel>
                                    </Tooltip>
                                )}
                                {(!user || (user && step.index > 2)) && (
                                    <StepLabel
                                        className={classes.stepLabel}
                                        onClick={handleStep(step.index)}
                                        StepIconComponent={StepIcon}
                                    ></StepLabel>
                                )}
                            </Step>
                        )
                    })}
                </Stepper>
                <div className={classes.content}>
                    {activeStep === CreatorSteps.WELCOME && <Welcome />}
                    {activeStep === CreatorSteps.PERSONAL_DATA && <PersonalDataForm />}
                    {activeStep === CreatorSteps.EMAIL_CONFIRMATION && <ConfirmEmailForm />}
                    {activeStep === CreatorSteps.CITY && <City />}
                    {activeStep === CreatorSteps.DATE_TYPE && <DateType />}
                    {activeStep === CreatorSteps.BASIC_QUESTIONS && <BasicQuestions />}
                    {activeStep === CreatorSteps.PERSONAL_DETAILS && <PersonalDetails />}
                    {activeStep === CreatorSteps.NOGOS_YOU && <NoGosAnswers />}
                    {activeStep === CreatorSteps.RATED_QUESTIONS && <RatedQuestions />}
                    {activeStep === CreatorSteps.SECRETS && <Secrets />}
                    {activeStep === CreatorSteps.PRODUCTS && <Products />}
                    {activeStep === CreatorSteps.DATES && <Dates />}
                </div>
            </Box>
        </div>
    )
}
