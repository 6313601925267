import { Gender, ISlot, MeetingType } from '.'
import { IMetadata } from './metadata'

// those are statuses about matching process, from admin perspective, so to say
export enum IMatchStatus {
    Pending = 'pending', // proposed by algorigthm, not yet acted on
    Confirmed = 'confirmed', // confirmed, if can be rejected, depending on position in time can be rated, chat etc
    Rejected = 'rejected', // rejected by user or by admin, user not shown in already matched with
}

// those are time statuses from user perspective, connected with chat/rating possibility
export enum IMatchTimeStatus {
    Pending = 'pending', // this should not happen to the user
    Future = 'future', // confirmed, awaiting, chat closed
    Current = 'current', // chat open
    PastWaiting = 'pastWaiting', // chat closed, waiting for ratings
    PastClosed = 'pastClosed', // chat closed afte the date, someone rated negative, won't be open
    PastOpen = 'pastOpen', // chat open after the datek
}

export interface IMatchTimeObject {
    status: IMatchTimeStatus
    dateChatOpens: string | null // iso
    dateChatCloses: string | null // iso
}

export interface IMatch extends IMatchAttributes {
    id: string
    users: string[]
}

export interface IMatchDao extends IMatchAttributes {
    PK: 'MATCH'
    SK: string
    SK2: string
    TYPE: string
}

export interface IMatchAttributes {
    meetingType: MeetingType
    status: IMatchStatus
    timeSlot: ISlot | null
    score: number
    ratings?: {
        [userId: string]: number // intended as 0 or 1
    }
    comments?: {
        [userId: string]: string
    }
    allSecretsBought?: {
        [userId: string]: boolean
    }
    singleSecretsBought?: {
        [userId: string]: string[] // question ids
    }
    publicInfo?: {
        [userId: string]: {
            name: string
            gender: Gender
        }
    }
    _metadata: IMetadata
}
