import { IMatchStatus, IMatchTimeStatus } from '@namuho/types';
import { addHours, isAfter, isBefore, subHours } from 'date-fns';
export const getMatchTimeStatus = (match) => {
    // this should not happen
    if (match.status === IMatchStatus.Pending) {
        return {
            status: IMatchTimeStatus.Pending,
            dateChatOpens: null,
            dateChatCloses: null,
        };
    }
    if (match.timeSlot === null) {
        return {
            status: IMatchTimeStatus.Pending,
            dateChatOpens: null,
            dateChatCloses: null,
        };
    }
    const { timeSlot } = match;
    const timeSlotDate = new Date(timeSlot.date);
    const now = new Date();
    const hoursBefore = 3;
    const hoursAfter = 2;
    const dateChatOpens = subHours(timeSlotDate, hoursBefore);
    const dateChatCloses = addHours(timeSlotDate, hoursAfter);
    // is before the date
    if (isBefore(now, dateChatOpens)) {
        return {
            status: IMatchTimeStatus.Future,
            dateChatOpens: dateChatOpens.toISOString(),
            dateChatCloses: dateChatCloses.toISOString(),
        };
    }
    // is after the date
    if (isAfter(now, dateChatCloses)) {
        const { ratings } = match;
        if (ratings === undefined) {
            return {
                status: IMatchTimeStatus.PastWaiting,
                dateChatOpens: null,
                dateChatCloses: null,
            };
        }
        const userIds = Object.keys(ratings);
        if (userIds.length !== 2) {
            return {
                status: IMatchTimeStatus.PastWaiting,
                dateChatOpens: null,
                dateChatCloses: null,
            };
        }
        const [user1, user2] = userIds;
        const user1Rating = ratings[user1];
        const user2Rating = ratings[user2];
        if (user1Rating === 1 || user2Rating === 1) {
            return {
                status: IMatchTimeStatus.PastOpen,
                dateChatOpens: null,
                dateChatCloses: null,
            };
        }
        else {
            return {
                status: IMatchTimeStatus.PastClosed,
                dateChatOpens: null,
                dateChatCloses: null,
            };
        }
    }
    // is during the date
    return {
        status: IMatchTimeStatus.Current,
        dateChatOpens: null,
        dateChatCloses: dateChatCloses.toISOString(),
    };
};
