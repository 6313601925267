import { HTMLProps } from 'react'
import { Link, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { TextInput } from './common/TextInput'
import { BirthdayInput } from './common/BirthDateInput'
import { useUser } from '@/hooks/user/useUser'

type ClassKeys = void | 'root' | 'editButton' | 'buttons'

const useStyles = makeStyles<ClassKeys>()((theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        alignItems: 'stretch',
        justifyContent: 'center',
    },
    buttons: {
        marginLeft: 'auto',
        display: 'flex',
        gap: theme.spacing(1),
    },
    editButton: {
        marginTop: theme.spacing(2),
        alignSelf: 'flex-end',
    },
}))

export const PersonalDataEdit = (props: HTMLProps<HTMLDivElement>) => {
    const { userData } = useUser()
    const { classes } = useStyles()

    return (
        <div {...props} className={classes.root}>
            {userData && (
                <>
                    <Typography variant="h2">Deine Stadt: {userData.city}</Typography>
                    <TextInput disabled value={userData.firstname} label="Vorname" />
                    <TextInput disabled value={userData.lastname} label="Nachname" />
                    <BirthdayInput name="birthDate" disabled value={userData.birthdate} label="Geburtsdatum" />
                    <TextInput disabled value={userData.gender} label="Geschlecht" />
                    <TextInput disabled label="E-Mail Addresse" type="email" value={userData.email} />
                    <TextInput disabled value={userData.country} label="Land" />
                    <Typography variant="caption">
                        Möchtest du Angaben anpassen, kontaktiere uns hier: <Link href="mailto:info@namuho.com">info@namuho.com</Link>
                    </Typography>
                </>
            )}
        </div>
    )
}
