import { Alert, Box, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { CreatorGo } from '@/components/creatorGo/CreatorGo'
import { Footer } from '@/components/nav/Footer'
import { useReadiness } from '@/hooks/user/useReadiness'
import namuhoCoffeePeople from '@/assets/namuho-coffee-people.png'
import { StepperProfileInfo } from '@/components/common/StepperProfileInfo'
import { useAuth } from '@/contexts'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { MeetingType } from '@namuho/types'

const useStyles = makeStyles()((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        gap: theme.spacing(2),
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        height: 'auto',
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(4),
        backgroundColor: theme.palette.background.romantic,
    },
    container: {
        width: '90vw',
        padding: theme.spacing(2),
        maxWidth: '100%',
        zIndex: 2,
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(2),
        },
    },
    back: {
        alignSelf: 'flex-start',
        marginLeft: theme.spacing(4),
        [theme.breakpoints.down('md')]: {
            marginLeft: theme.spacing(2),
        },
    },
    title: {
        zIndex: 2,
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            marginLeft: theme.spacing(2),
        },
    },
    bgImage: {
        position: 'absolute',
        width: 200,
        bottom: 200,
        left: 10,
    },
}))

export function Group() {
    const { classes } = useStyles()
    const { [MeetingType.Group]: group } = useReadiness()
    const { user, loading } = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        if (!loading && !user) {
            navigate('/auth/sign-in', { replace: true })
        }
    }, [user, loading, navigate])

    return (
        <>
            <img src={namuhoCoffeePeople} alt="People drinking coffee" className={classes.bgImage} />
            <Box component="main" className={classes.root}>
                <StepperProfileInfo />
                <Typography className={classes.title} variant="h1">
                    Eine Gruppe von Freunden treffen
                </Typography>
                {(group.waiting || group.datesMatched) && <Alert severity="success">Alles bereit, wir bereiten deine Matches vor</Alert>}
                <Box className={classes.container}>
                    <CreatorGo dateType={MeetingType.Group} />
                </Box>
            </Box>
            <Footer />
        </>
    )
}
