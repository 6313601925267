import { useEffect, useState } from 'react'
import { SecretsForm } from '@/types/Questions'
import { makeStyles } from 'tss-react/mui'
import { SecretsSkeleton } from '@/skeletons/SecretsSkeleton'
import { useForm } from 'react-hook-form'
import { IQuestion, ISecretsQuestionAnswer, MeetingType } from '@namuho/types'
import { usePreferences } from '@/hooks/preferences/usePreferences'
import { useQuestions } from '@/hooks/questions/useQuestions'
import { isEmptyAnswer } from '@namuho/helpers'
import { TextInput } from './common/TextInput'
import { Button } from '@mui/material'

const useStyles = makeStyles()((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        alignItems: 'stretch',
        justifyContent: 'center',
    },
    form: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(2),
        width: '100%',
    },
    buttons: {
        marginLeft: 'auto',
        display: 'flex',
        gap: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(2),
        alignSelf: 'flex-end',
    },
}))

export interface FillMissingSecretsProps {
    meetingType: MeetingType
    onCancel: () => void
    onSave: () => void
}

export const FillMissingSecrets = (props: FillMissingSecretsProps) => {
    const { meetingType, onCancel, onSave } = props
    const { classes } = useStyles()
    const [loading, setLoading] = useState(false)
    const {
        isLoading: isLoadingPreferences,
        friendlyPreferences,
        romanticPreferences,
        updateFriendlyPreferences,
        updateRomanticPreferences,
    } = usePreferences()
    const { secrets, isLoading: isLoadingQuestions } = useQuestions()

    const { register, getValues, setValue, watch } = useForm<SecretsForm>()

    const handleCancel = () => {
        onCancel()
    }

    const secretsToAnswer = secrets?.filter((question: IQuestion) => question.meetingType === meetingType) || []
    const answers = watch()
    // for some reason answers get populated with more qeustions
    const nonEmptyAnswers = Object.keys(answers).filter(
        (id: string) => !isEmptyAnswer(answers[id]) && secretsToAnswer.map((question: IQuestion) => question.id).includes(id)
    )
    const allSecretsAnswered = nonEmptyAnswers.length === secretsToAnswer.length

    const handleSave = async () => {
        const values = getValues()
        setLoading(true)
        const answers: ISecretsQuestionAnswer[] = Object.keys(values)
            .map((key) => ({
                questionId: key,
                answer: values[key],
            }))
            .filter((answer) => secretsToAnswer.map((question: IQuestion) => question.id).includes(answer.questionId))
        if (meetingType === MeetingType.Friendly) {
            await updateFriendlyPreferences({
                ...friendlyPreferences,
                secretQuestions: answers,
            })
        }
        if (meetingType === MeetingType.Romantic) {
            await updateRomanticPreferences({
                ...romanticPreferences,
                secretQuestions: answers,
            })
        }
        setLoading(false)
        onSave()
    }

    useEffect(() => {
        try {
            if (friendlyPreferences) {
                const secretsAnswers = friendlyPreferences.secretQuestions
                secretsAnswers?.forEach((answer: ISecretsQuestionAnswer) => {
                    setValue(answer.questionId, answer.answer)
                })
            }
            if (romanticPreferences) {
                const secretsAnswers = romanticPreferences.secretQuestions
                secretsAnswers?.forEach((answer: ISecretsQuestionAnswer) => {
                    setValue(answer.questionId, answer.answer)
                })
            }
        } catch (e) {
            console.log(e)
        }
    }, [friendlyPreferences, romanticPreferences, setValue])

    const loadingState = isLoadingPreferences || isLoadingQuestions || loading

    return (
        <div className={classes.root}>
            {loadingState && <SecretsSkeleton />}
            {!loadingState && secretsToAnswer && secretsToAnswer.length > 0 && (
                <form className={classes.form}>
                    {secretsToAnswer.map((secret: IQuestion) => (
                        <TextInput key={secret.id} label={secret.label} {...register(secret.id)} />
                    ))}
                </form>
            )}
            <div className={classes.buttons}>
                <Button className={classes.button} variant="contained" disabled={loadingState} color="secondary" onClick={handleCancel}>
                    Stornieren
                </Button>
                <Button
                    className={classes.button}
                    disabled={!allSecretsAnswered || loadingState}
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                >
                    Speichern
                </Button>
            </div>
        </div>
    )
}
