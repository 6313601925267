import clsx from 'clsx'
import { ChangeEvent, forwardRef } from 'react'
import { makeStyles } from 'tss-react/mui'

export interface CheckboxesInputOption {
    value: string
    label: string
}

export interface CheckboxesInputProps {
    name: string
    options: CheckboxesInputOption[]
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void
    label?: string
    error?: string
    className?: string
    rated?: boolean
}

const useStyles = makeStyles()((theme) => ({
    root: {
        display: 'grid',
        gridTemplateColumns: '1fr 3fr',
        border: 'none',
        gap: theme.spacing(2),
        width: '100%',
        padding: 0,
        margin: 0,
        minWidth: 0,
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: theme.spacing(2),
            gap: theme.spacing(1),
        },
    },
    rated: {
        gridTemplateColumns: '3fr 3fr',
    },
    label: {
        width: '100%',
        textAlign: 'left',
        display: 'flex',
        alignItems: 'center',
        padding: 0,
    },
    ratedLabel: {
        fontSize: theme.typography.pxToRem(12),
        fontWeight: theme.typography.fontWeightBold,
    },
    input: {
        display: 'flex',
        gap: theme.spacing(2),
        justifyContent: 'start',
        flexWrap: 'wrap',
        border: 'none',
        padding: theme.spacing(1),
        flexGrow: 2,
        '&:focus': {
            borderRadius: 0,
        },
        '& label': {
            marginBottom: 0,
        },
    },
    singleOption: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'left',
        gap: theme.spacing(2),
        maxWidth: '100%',
    },
    singleLabel: {
        maxWidth: '100%',
        overflowWrap: 'break-word',
        hyphens: 'auto',
        [theme.breakpoints.down('md')]: {
            fontSize: '1rem',
        },
    },
    inputWrapper: {
        width: 25,
    },
}))

export const CheckboxesInput = forwardRef<HTMLInputElement, CheckboxesInputProps>((props: CheckboxesInputProps, ref) => {
    const { name, label, options, error, rated, onChange, ...rest } = props
    const { classes } = useStyles()
    return (
        <fieldset className={clsx(classes.root, rated && classes.rated)}>
            {label && <span className={clsx(classes.label, rated && classes.ratedLabel)}>{label}</span>}
            <div className={classes.input} {...rest}>
                {options.map((option) => (
                    <label className={clsx(classes.singleOption)} key={option.value} htmlFor={`${name}-${option.label}`}>
                        <div className={classes.inputWrapper}>
                            <input
                                name={name}
                                id={`${name}-${option.label}`}
                                type="checkbox"
                                value={option.value}
                                ref={ref}
                                onChange={onChange}
                                {...rest}
                            ></input>
                        </div>
                        <span className={classes.singleLabel}>{option.label}</span>
                    </label>
                ))}
            </div>
            {error && <span>{error}</span>}
        </fieldset>
    )
})

CheckboxesInput.displayName = 'CheckboxesInput'
