import { ArrowUpwardSharp } from '@mui/icons-material'
import { HideOn } from 'react-hide-on-scroll'
import * as Scroll from 'react-scroll'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
    root: {
        display: 'none',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            position: 'fixed',
            bottom: 20,
            right: 20,
            zIndex: theme.zIndex.drawer + 1,
        },
    },
}))

export const JumpToTopArrow = () => {
    const { classes } = useStyles()
    const handleArrowClick = () => {
        Scroll.animateScroll.scrollToTop()
    }

    return (
        <HideOn inverse height={400}>
            <div className={classes.root}>
                <ArrowUpwardSharp onClick={handleArrowClick} />
            </div>
        </HideOn>
    )
}
