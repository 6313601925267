import { ConsumableMatch, useUserMatches } from '@/hooks/user/useUserMatches'
import { RootState } from '@/store'
import { Typography } from '@mui/material'
import { IMatch } from '@namuho/types'
import { useSelector } from 'react-redux'
import { makeStyles } from 'tss-react/mui'
import { ChatMessages } from './ChatMessages'
import { EditWindow } from './EditWindow'

const useStyles = makeStyles()((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: 'max-content',
        gap: theme.spacing(1),
    },
    meta: {
        marginTop: theme.spacing(4),
        color: theme.palette.grey[500],
        display: 'flex',
        gap: theme.spacing(1),
        alignSelf: 'end',
    },
}))

export const Chat = () => {
    const { matches, isLoading } = useUserMatches()
    const activeDateId = useSelector((state: RootState) => state.meetings.activeDate)
    const activeDate: ConsumableMatch = matches.find((match: ConsumableMatch) => match.id === activeDateId) || matches[0]
    const { classes } = useStyles()
    return (
        <div className={classes.root}>
            <ChatMessages match={activeDate} />
            <EditWindow match={activeDate} />
            <div className={classes.meta}>
                {activeDateId && <Typography variant="caption">id: {activeDateId}</Typography>}
                <Typography variant="caption" component="a" href="mailto:info@namuho.com">
                    Chat melden
                </Typography>
            </div>
        </div>
    )
}
