import { useEffect } from 'react'
import 'vanilla-cookieconsent'

declare global {
    interface Window {
        CC: any
    }
}

export default function CookieConsent() {
    useEffect(() => {
        if (!document.getElementById('cc--main')) {
            window.CC = window.initCookieConsent()
            window.CC.run({
                // your config (required)
                current_lang: 'de',
                page_scripts: true, // default: false
                cookie_expiration: 365, // default: 182 (days)
                cookie_domain: 'namuho.com', // default: current domain

                languages: {
                    de: {
                        consent_modal: {
                            title: 'Wir verwenden Cookies',
                            description:
                                'Hallo, diese Website verwendet Tracking-Cookies, um zu verstehen, wie Sie mit ihr interagieren. Sie werden nur nach Zustimmung genutzt.',
                            primary_btn: {
                                text: 'Akzeptieren',
                                role: 'accept_all', // 'accept_selected' or 'accept_all'
                            },
                            secondary_btn: {
                                text: 'Ablehnen',
                                role: 'accept_necessary', // 'settings' or 'accept_necessary'
                            },
                        },
                        settings_modal: {
                            title: 'Cookie preferences',
                            save_settings_btn: 'Save settings',
                            accept_all_btn: 'Accept all',
                            reject_all_btn: 'Reject all',
                            close_btn_label: 'Close',
                            cookie_table_headers: [{ col1: 'Name' }, { col2: 'Domain' }, { col3: 'Expiration' }, { col4: 'Description' }],
                            blocks: [
                                {
                                    title: 'Cookie usage 📢',
                                    description:
                                        'I use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="#" class="cc-link">privacy policy</a>.',
                                },
                                {
                                    title: 'Facebook pixel',
                                    description: 'Facebook pixel',
                                    toggle: {
                                        value: 'facebook',
                                        enabled: false,
                                        readonly: false,
                                    },
                                },
                                {
                                    title: 'Google analytics',
                                    description: 'Google analytcics',
                                    toggle: {
                                        value: 'analytics', // your cookie category
                                        enabled: false,
                                        readonly: false,
                                    },
                                    cookie_table: [],
                                },
                                {
                                    title: 'More information',
                                    description:
                                        'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="#yourcontactpage">contact us</a>.',
                                },
                            ],
                        },
                    },
                },
            })
        }
    }, [])

    return null
}
