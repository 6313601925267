import { forwardRef, InputHTMLAttributes } from 'react'
import { makeStyles } from 'tss-react/mui'

export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string
    error?: string
    helperText?: string
}

type ClassKeys = void | 'root' | 'input' | 'label'

const useStyles = makeStyles<ClassKeys>()((theme) => ({
    root: {
        display: 'grid',
        gridTemplateColumns: '1fr 3fr',
        alignItems: 'center',
        gap: theme.spacing(2),
        width: '100%',
        margin: 0,
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: theme.spacing(2),
            gap: theme.spacing(1),
        },
    },
    label: {
        width: '100%',
        display: 'block',
        textAlign: 'left',
    },
    input: {
        border: 'none',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        flexGrow: 2,
        width: '100%',
        '&:focus': {
            borderRadius: 0,
        },
        '&:disabled': {
            color: theme.palette.common.black,
            backgroundColor: theme.palette.secondary.main,
        },
    },
    inputHelperWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        width: '100%',
    },
    helperText: {
        fontSize: '0.85rem',
        color: 'silver',
    },
}))

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>((props: TextInputProps, ref) => {
    const { name, label, error, helperText, ...rest } = props
    const { classes } = useStyles()
    return (
        <label className={classes.root} htmlFor={name}>
            {label && <span className={classes.label}>{label}</span>}
            <div className={classes.inputHelperWrapper}>
                <input name={name} className={classes.input} {...rest} ref={ref} />
                {error && <span>{error}</span>}
                {helperText && <span className={classes.helperText}>{helperText}</span>}
            </div>
        </label>
    )
})

TextInput.displayName = 'TextInput'
