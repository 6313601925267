import { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useForm } from 'react-hook-form'
import { RadioInput } from '../common/RadioInput'
import { StepContent } from '../stepper/StepContent'
import { useDispatch } from 'react-redux'
import { CreatorGoSteps, markStepCompleted, nextStep } from '@/slices/creatorGo'
import { CreatorButtonsGo } from './CreatorButtonsGo'
import clsx from 'clsx'
import { CheckboxesInput } from '../common/CheckboxesInput'
import { CreatorGoProps } from './CreatorGo'
import { AlcoholConsumption, BodyType, IPersonalDetails, Pet, SmokingConsumption } from '@namuho/types'
import { usePreferences } from '@/hooks/preferences/usePreferences'
import { HeightInput } from '../common/HeightInput'
import { DottedDivider } from '../common/DottedDivider'
import { StepTitle } from '../stepper/StepTitle'

const useStyles = makeStyles()((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(2),
    },
    divider: {
        width: '100%',
        borderBottom: `1px dotted ${theme.palette.primary.main}`,
    },
    heightLabel: {
        display: 'grid',
        gridTemplateColumns: '1fr 3fr',
        alignItems: 'center',
        border: 'none',
        gap: theme.spacing(2),
        width: '100%',
        padding: 0,
        margin: 0,
        marginTop: theme.spacing(2),
        textAlign: 'left',
        minWidth: 0,
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: theme.spacing(2),
            gap: theme.spacing(4),
        },
    },
    loading: {
        opacity: '50%',
    },
}))

export const PersonalDetailsGo = (props: CreatorGoProps) => {
    const { dateType } = props
    const { register, getValues, setValue, watch } = useForm<IPersonalDetails>({
        defaultValues: {
            height: 170,
            pets: [],
        },
    })
    const { classes } = useStyles()
    const dispatch = useDispatch()
    const [loading, setIsLoading] = useState(false)
    const { personalDetails, updatePersonalDetails, isLoading: isLoadingPreferences } = usePreferences()

    const handleNext = () => {
        setIsLoading(true)
        const values = getValues()
        updatePersonalDetails(values)
        setIsLoading(false)
        dispatch(markStepCompleted({ dateType: dateType, step: CreatorGoSteps.NOGOS_YOU }))
        dispatch(nextStep(dateType))
    }

    const handleHeightChange = (height: number | number[]) => {
        if (typeof height === 'number') {
            setValue('height', height)
        }
    }

    useEffect(() => {
        try {
            if (personalDetails) {
                Object.keys(personalDetails).forEach((key) => {
                    setValue(key as keyof IPersonalDetails, personalDetails[key])
                })
            }
        } catch (e) {
            console.log(e)
        }
    }, [personalDetails, setValue])

    return (
        <StepContent
            title={<StepTitle title="Infos über dich" />}
            content={
                <form className={clsx(classes.root, loading && classes.loading)}>
                    <RadioInput
                        label="Rauchst du?"
                        options={(Object.values(SmokingConsumption) as Array<SmokingConsumption>).map((type) => ({
                            label: type,
                            value: type,
                        }))}
                        {...register('smokingConsumption')}
                    />
                    <DottedDivider />
                    <RadioInput
                        label="Deine Figur?"
                        options={(Object.values(BodyType) as Array<BodyType>).map((type) => ({
                            label: type,
                            value: type,
                        }))}
                        {...register('bodyType')}
                    />
                    <DottedDivider />
                    <label className={classes.heightLabel} htmlFor="height">
                        <span>Deine Größe</span>
                        <HeightInput value={watch('height')} handleChange={handleHeightChange} />
                    </label>
                    <DottedDivider />
                    <RadioInput
                        label="Dein Alkoholkonsum?"
                        options={(Object.values(AlcoholConsumption) as Array<AlcoholConsumption>).map((type) => ({
                            label: type,
                            value: type,
                        }))}
                        {...register('alcoholConsumption')}
                    />
                    <DottedDivider />
                    <RadioInput
                        label="Hast du Kinder?"
                        options={[
                            { value: 'true', label: 'Ja' },
                            { value: 'false', label: 'Nein' },
                        ]}
                        {...register('kids')}
                    />
                    <DottedDivider />
                    <CheckboxesInput
                        label="Hast du Haustiere?"
                        options={(Object.values(Pet) as Array<Pet>).map((type) => ({
                            label: type,
                            value: type,
                        }))}
                        {...register('pets')}
                    />
                </form>
            }
            buttons={
                <CreatorButtonsGo
                    dateType={dateType}
                    nextStepActive={true}
                    previousStepActive={true}
                    isFirst={false}
                    isLast={false}
                    handleNext={handleNext}
                />
            }
        />
    )
}
