import React from 'react'
import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

type ClassKeys = void | 'root'

const useStyles = makeStyles<ClassKeys>()((theme) => ({
    root: {
        color: theme.palette.primary.contrastText,
        marginBottom: theme.spacing(1),
    },
}))

export interface NavHeadlineProps {
    children: React.ReactNode
}

export function NavHeadline(props: NavHeadlineProps) {
    const { children } = props
    const { classes } = useStyles()

    return (
        <Typography className={classes.root} variant="h1">
            {children}
        </Typography>
    )
}
