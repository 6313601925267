import { ButtonHTMLAttributes } from 'react'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    label?: string
    variant?: 'filled' | 'outline' | 'link'
}

export function Button({ label, type = 'button', variant = 'filled', ...restProps }: ButtonProps) {
    switch (variant) {
        case 'link':
            return (
                <button
                    type={type}
                    className="text-pink-600 hover:underline focus:outline-none font-medium text-sm text-left transition"
                    {...restProps}
                >
                    {label}
                </button>
            )
        default:
            return (
                <button
                    type={type}
                    className="w-full text-white bg-pink-600 hover:bg-pink-700 focus:ring-4 focus:outline-none focus:ring-pink-300 font-medium text-sm px-5 py-2.5 text-center dark:bg-pink-600 dark:hover:bg-pink-700 dark:focus:ring-pink-800 transition"
                    {...restProps}
                >
                    {label}
                </button>
            )
    }
}
