import { setDefaultOptions, format, parseISO } from 'date-fns';
import { de } from 'date-fns/locale';
setDefaultOptions({ locale: de });
// get date in iso format and attempt to parse and format it,
// if it fails, return the original string
export const formatDate = (date) => {
    if (typeof date === null) {
        return '';
    }
    if (typeof date === undefined) {
        return '';
    }
    try {
        const parsedDate = parseISO(date);
        return format(parsedDate, 'PPPP p');
    }
    catch (e) {
        return date;
    }
};
// get date in iso format and attempt to parse and format it,
// if it fails, return the original string
export const formatDateDayOnly = (date) => {
    if (typeof date === null) {
        return '';
    }
    if (typeof date === undefined) {
        return '';
    }
    try {
        const parsedDate = parseISO(date);
        return format(parsedDate, 'PPPP');
    }
    catch (e) {
        return date;
    }
};
export const formatDateDaySimple = (date) => {
    if (typeof date === null) {
        return '';
    }
    if (typeof date === undefined) {
        return '';
    }
    try {
        const parsedDate = parseISO(date);
        return format(parsedDate, 'PP');
    }
    catch (e) {
        return date;
    }
};
// get date in iso format and attempt to parse and format it,
// if it fails, return the original string
export const formatDateHourOnly = (date) => {
    if (typeof date === null) {
        return '';
    }
    if (typeof date === undefined) {
        return '';
    }
    try {
        const parsedDate = parseISO(date);
        return format(parsedDate, 'p');
    }
    catch (e) {
        return date;
    }
};
