import { ResetPasswordForm } from '@/components'
import { Footer } from '@/components/nav/Footer'

export function ResetPasswordPage() {
    return (
        <>
            <section className="bg-gray-50 dark:bg-gray-900">
                <ResetPasswordForm />
            </section>
            <Footer />
        </>
    )
}
