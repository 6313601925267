import { useEffect, useState } from 'react'
import { StepContent } from './StepContent'
import { CreatorButtons } from './CreatorButtons'
import { useNavigate } from 'react-router-dom'
import { usePreferences } from '@/hooks/preferences/usePreferences'
import { useUser } from '@/hooks/user/useUser'
import { useSlots } from '@/hooks/slots/useSlots'
import { ISlot } from '@namuho/types'
import { TimeSlotsSelection } from '../common/TimeSlotsSelection'
import { StepTitle } from './StepTitle'

export const Dates = () => {
    const { userData, isLoading } = useUser()
    const { isLoading: isLoadingSlots } = useSlots()
    const navigate = useNavigate()
    const [selectedDates, setSelectedDates] = useState<ISlot[]>([])
    const { updateTimeSlots } = usePreferences()

    const handleNext = async () => {
        updateTimeSlots(selectedDates)
        navigate('/')
    }

    const handleTimeSlotAdd = (slot: ISlot) => {
        setSelectedDates((prev) => {
            const alreadySelectedIds = prev.map((slot) => slot.id)
            const newSlotId = slot.id
            if (alreadySelectedIds.includes(newSlotId)) {
                return prev
            } else {
                return [...prev, slot]
            }
        })
    }

    const handleTimeSlotRemove = (slot: ISlot) => {
        setSelectedDates((prev) => prev.filter((date) => date.id !== slot.id))
    }

    useEffect(() => {
        const availableDates = userData?.timeSlots
        setSelectedDates(availableDates ?? [])
    }, [userData?.timeSlots])

    return (
        <StepContent
            title={<StepTitle title="Bitte teile uns deine möglichen Termine mit (mindestens 3)" />}
            content={<TimeSlotsSelection onTimeSlotAdd={handleTimeSlotAdd} onTimeSlotRemove={handleTimeSlotRemove} timeSlots={selectedDates} />}
            buttons={
                <CreatorButtons
                    nextStepActive={selectedDates.length > 2}
                    previousStepActive={true}
                    isFirst={false}
                    isLast={true}
                    skipActive={true}
                    handleNext={handleNext}
                />
            }
        />
    )
}
