import { setActiveDate } from '@/slices/meetings'
import { Paper, Typography } from '@mui/material'
import { CalendarMonth as CalendarIcon } from '@mui/icons-material/'
import { useDispatch } from 'react-redux'
import { makeStyles } from 'tss-react/mui'
import { StatusBar } from './StatusBar'
import { getDisplayedGender } from './helpers'
import { ConsumableMatch } from '@/hooks/user/useUserMatches'
import { IMatchTimeObject, IMatchTimeStatus } from '@namuho/types'
import { formatDate } from '@namuho/helpers'

const useStyles = makeStyles()((theme) => ({
    root: {
        padding: theme.spacing(2),
        cursor: 'pointer',
        color: theme.palette.primary.contrastText,
    },
    topRow: {
        display: 'flex',
        gap: theme.spacing(1),
    },
    future: {
        padding: theme.spacing(1),
    },
    date: {
        display: 'flex',
        gap: theme.spacing(1),
    },
}))

export interface MainDateInfoProps {
    meeting: ConsumableMatch
}

export const MainDateInfo = (props: MainDateInfoProps) => {
    const {
        meeting: { name, timeSlot, gender, id, timeObject },
    } = props
    const dispatch = useDispatch()
    const { classes } = useStyles()

    const handleClick = () => {
        dispatch(setActiveDate(id))
    }
    const getDisplayedStatus = (timeObject: IMatchTimeObject) => {
        if (timeObject.status === IMatchTimeStatus.Future) {
            return (
                <div className={classes.future}>
                    <Typography variant="h2">{`Chatten am ${formatDate(timeObject.dateChatOpens)}`}</Typography>
                </div>
            )
        }
    }

    return (
        <Paper className={classes.root} onClick={handleClick}>
            <div className={classes.topRow}>
                <Typography variant="h1">
                    {name} {getDisplayedGender(gender)}
                </Typography>
                <div className={classes.date}>
                    <CalendarIcon />
                    <Typography variant="subtitle2">{formatDate(timeSlot?.date)}</Typography>
                </div>
            </div>

            {getDisplayedStatus(timeObject)}
            <StatusBar date={props.meeting} />
        </Paper>
    )
}
