import { NewPasswordRequiredForm } from '@/components'
import { Footer } from '@/components/nav/Footer'

export function NewPasswordRequiredPage() {
    return (
        <>
            <section className="bg-gray-50 dark:bg-gray-900">
                <NewPasswordRequiredForm />
            </section>
            <Footer />
        </>
    )
}
