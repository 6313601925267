import { NoGos } from '@/types/Questions'
import { Checkbox, Typography } from '@mui/material'
import clsx from 'clsx'
import { forwardRef, ReactElement } from 'react'
import { makeStyles } from 'tss-react/mui'
import { NoGos_Questions } from './NoGosQuestions'

export interface NoGoQuestionProps {
    question: NoGos_Questions
    active: boolean
    children: ReactElement
    onSelect: (questionId: NoGos) => void
}

const useStyles = makeStyles()((theme) => ({
    root: {
        display: 'grid',
        gridTemplateColumns: '2fr 8fr',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: theme.spacing(2),
        width: '100%',
        opacity: '60%',
        marginLeft: theme.spacing(4),
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            display: 'flex',
            flexDirection: 'column',
        },
        paddingBottom: theme.spacing(1),
        borderBottom: `1px dotted ${theme.palette.primary.main}`,
    },
    head: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginLeft: `-${theme.spacing(4)}`,
    },
    active: {
        opacity: '1 !important',
    },
    hidden: {
        visibility: 'hidden',
        height: 0,
    },
    content: {
        width: '100%',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    instructions: {
        alignSelf: 'flex-start',
    },
    kidsInstructions: {
        textAlign: 'start',
    },
    answers: {
        display: 'flex',
        width: '100%',
    },
    checkbox: {},
}))

export const NoGoQuestion = forwardRef<HTMLInputElement, NoGoQuestionProps>((props: NoGoQuestionProps, _ref) => {
    const { question, active, onSelect, children } = props
    const { classes } = useStyles()

    const onCheckboxChange = () => {
        onSelect(question.id as NoGos)
    }

    const questionAboutKids = question.id === 'noKids'

    return (
        <div className={clsx(classes.root, active && classes.active)}>
            <div className={classes.head} onClick={onCheckboxChange}>
                <Checkbox checked={active} onChange={onCheckboxChange} />
                <Typography variant="body2">{question.title}</Typography>
            </div>
            <div className={classes.content}>
                <Typography
                    variant="h2"
                    onClick={onCheckboxChange}
                    className={clsx(classes.instructions, questionAboutKids && classes.kidsInstructions)}
                >
                    {question.instruction}
                </Typography>
                <div className={clsx(classes.answers, questionAboutKids && classes.hidden)}>{children}</div>
            </div>
        </div>
    )
})

NoGoQuestion.displayName = 'NoGoQuestion'
