export const heightMarks = [
    {
        value: 120,
        label: '120',
    },
    { value: 145, label: '145' },
    { value: 170, label: '170' },
    { value: 195, label: '195' },
    { value: 220, label: '220' },
]
