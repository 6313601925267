import { ChangeEvent, InputHTMLAttributes, useCallback } from 'react'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    value: string
    setValue: (value: string) => void
    label?: string
    type?: 'text' | 'email' | 'password' | 'number'
    containerClassName?: string
}

export function Input({ value, setValue, label, type = 'text', containerClassName, id, ...restProps }: InputProps) {
    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setValue(event.target.value)
        },
        [setValue]
    )

    return (
        <div className={containerClassName}>
            {label && (
                <label htmlFor={id} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    {label}
                </label>
            )}
            <input
                id={id}
                type={type}
                className="bg-gray-50 border border-gray-300 text-gray-900 disabled:text-gray-400 sm:text-sm focus:ring-pink-600 focus:border-pink-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:disabled:text-gray-500 dark:focus:ring-pink-600 dark:focus:border-pink-600 focus:outline-none"
                value={value}
                onChange={handleChange}
                {...restProps}
            />
        </div>
    )
}
