import { IconButton, Menu, MenuItem } from '@mui/material'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { makeStyles } from 'tss-react/mui'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useAuth } from '@/contexts'
import { useDispatch } from 'react-redux'
import { clearUserData } from '@/slices/user'
import { resetSteps } from '@/slices/creator'

const useStyles = makeStyles()((theme) => ({
    root: {
        position: 'absolute',
        top: 0,
        left: 0,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.secondary.main,
    },
    button: {
        color: theme.palette.common.white,
        [theme.breakpoints.down('md')]: {
            fontSize: '1.5rem',
        },
    },
    menu: {
        '& .MuiPaper-root': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.common.white,
        },
    },
    inner: {
        maxHeight: 0,
        overflow: 'hidden',
        transition: 'max-height 500ms ease-in-out',
        '&.valid': {
            maxHeight: 300,
        },
    },
}))
export const StepperProfileInfo = () => {
    const { classes, cx } = useStyles()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const [userName, setUserName] = useState<string | null>(null)
    const { signOut, userAttributes } = useAuth()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    function logOut() {
        signOut()
        dispatch(clearUserData())
        dispatch(resetSteps())
        navigate('/auth/sign-in')
    }

    useEffect(() => {
        const fetchUser = async () => {
            const name = userAttributes?.given_name || null
            setUserName(name)
        }
        fetchUser()
    }, [userAttributes?.given_name])

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const navigateToDashboard = () => {
        navigate('/')
        setAnchorEl(null)
    }

    return (
        <div className={classes.root}>
            <div className={cx(classes.inner, userName && 'valid')}>
                <IconButton className={classes.button} onClick={handleClick}>
                    {`Hey {${userName}}`} {open ? <ArrowDropUp /> : <ArrowDropDown />}
                </IconButton>
                <Menu classes={{ root: classes.menu }} open={open} onClose={handleClose} anchorEl={anchorEl}>
                    <MenuItem onClick={navigateToDashboard}>Dashboard</MenuItem>
                    <MenuItem onClick={logOut}>Ausloggen</MenuItem>
                </Menu>
            </div>
        </div>
    )
}
