import React from 'react'
import { Box, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

export interface StepContentProps {
    title?: React.ReactNode
    variant?: 'default' | 'transparent'
    content: React.ReactNode
    buttons: React.ReactNode
}

const useStyles = makeStyles<{ variant: string }>()((theme, { variant }) => ({
    root: {
        padding: variant === 'default' ? theme.spacing(2) : 0,
        paddingLeft: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        gap: theme.spacing(2),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(4),
        },
    },
    content: {
        backgroundColor: variant === 'default' ? theme.palette.common.white : 'transparent',
        padding: variant === 'default' ? theme.spacing(4) : 0,
        maxWidth: '100%',
        [theme.breakpoints.down('md')]: {
            padding: variant === 'default' ? theme.spacing(2) : 0,
        },
    },
    buttons: {
        marginTop: theme.spacing(2),
    },
}))

export function StepContent(props: StepContentProps) {
    const { content, buttons, title, variant = 'default' } = props
    const { classes } = useStyles({ variant })
    return (
        <Box className={classes.root}>
            <div className={classes.content}>
                {title}
                {content}
            </div>
            <div className={classes.buttons}>{buttons}</div>
        </Box>
    )
}
