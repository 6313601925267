import { Outlet, useLocation, useSearchParams } from 'react-router-dom'
import { AuthProvider } from '@/contexts'
import { Provider } from 'react-redux'
import { store } from '../store'
import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import { setDefaultOptions } from 'date-fns'
import { de } from 'date-fns/locale'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import deTranslations from '../translations/de.json'

import '@/styles/index.css'
import '@/styles/index_tailwind.css'
import theme from '@/theme/theme'
import ScrollToTop from '@/components/ScrollToTop'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'

setDefaultOptions({ locale: de })

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            de: {
                translation: deTranslations,
            },
        },
        lng: 'de', // if you're using a language detector, do not define the lng option
        fallbackLng: 'de',

        interpolation: {
            escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },
    })

const initialPaypalOptions = {
    clientId: import.meta.env.VITE_PAYPAL_CLIENT_ID || '',
    currency: 'EUR',
    intent: 'capture',
}

export function RootPage() {
    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <AuthProvider>
                    <PayPalScriptProvider options={initialPaypalOptions}>
                        <Outlet />
                    </PayPalScriptProvider>
                </AuthProvider>
            </ThemeProvider>
            <ScrollToTop />
        </Provider>
    )
}
