import { forwardRef } from 'react'
import { makeStyles } from 'tss-react/mui'

export interface SelectInputOption {
    value: string
    label: string
}

export interface SelectInputProps {
    name: string
    options: SelectInputOption[]
    label?: string
    error?: string
}

type ClassKeys = void | 'root' | 'input' | 'label'

const useStyles = makeStyles<ClassKeys>()((theme) => ({
    root: {
        display: 'grid',
        gridTemplateColumns: '1fr 3fr',
        gap: theme.spacing(2),
        width: '100%',
        margin: 0,
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: theme.spacing(2),
            gap: theme.spacing(1),
        },
    },
    label: {
        textAlign: 'left',
        display: 'block',
    },
    input: {
        border: 'none',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        flexGrow: 2,
        '&:focus': {
            borderRadius: 0,
        },
    },
}))

export const SelectInput = forwardRef<HTMLSelectElement, SelectInputProps>((props: SelectInputProps, ref) => {
    const { name, label, options, error, ...rest } = props
    const { classes } = useStyles()
    return (
        <label className={classes.root} htmlFor={name}>
            {label && <span className={classes.label}>{label}</span>}
            <select className={classes.input} name={name} {...rest} ref={ref}>
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
            {error && <span>{error}</span>}
        </label>
    )
})

SelectInput.displayName = 'SelectInput'
