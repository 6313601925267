import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '@/contexts'
import { Button, Input } from '@/components/common'

export function ForgotPasswordForm() {
    const { forgotPassword } = useAuth()

    const [email, setEmail] = useState('')

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        forgotPassword(email)
    }

    return (
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
            <Link to="/" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                <img src="/logo.svg" alt="Logo" className="w-80" />
            </Link>
            <div className="w-full bg-white shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">Passwort vergessen</h1>
                    <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                        <Input
                            type="email"
                            name="email"
                            id="email"
                            autoComplete="email"
                            placeholder="name@email.com"
                            label="E-mail"
                            value={email}
                            setValue={setEmail}
                            required
                        />

                        <Button type="submit" label="Reset-Link senden" />

                        <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                            Haben Sie bereits einen Reset-Code?{' '}
                            <Link to="/auth/reset-password" className="font-medium text-pink-600 hover:underline dark:text-pink-600">
                                Passwort hier zurücksetzen
                            </Link>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    )
}
