import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import * as Sentry from '@sentry/react'

import {
    ErrorPage,
    RootPage,
    SignInPage,
    AuthPage,
    Dashboard,
    Dates,
    Go,
    Friends,
    Romantic,
    NewPasswordRequiredPage,
    ForgotPasswordPage,
    ResetPasswordPage,
    Start,
    ConfirmEmailPage,
    Group,
} from '@/routes'
import CookieConsent from './components/CookieConsent'

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://38482ec48a334b25948ac8d62b2499f9@o4505051276181505.ingest.sentry.io/4505051277754368',
    })
}
declare global {
    interface Window {
        goatcounter: any
    }
}

const queryClient = new QueryClient()

const router = createBrowserRouter([
    {
        path: '/',
        element: <RootPage />,
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                element: <Dashboard />,
            },
            {
                path: 'auth',
                element: <AuthPage />,
                children: [
                    {
                        index: true,
                        element: <Navigate to="sign-in" />,
                    },
                    {
                        path: 'sign-in',
                        element: <SignInPage />,
                    },
                    {
                        path: 'confirm-email',
                        children: [
                            {
                                index: true,
                                element: <ConfirmEmailPage />,
                            },
                            {
                                path: ':email',
                                element: <ConfirmEmailPage />,
                            },
                        ],
                    },
                    {
                        path: 'forgot-password',
                        element: <ForgotPasswordPage />,
                    },
                    {
                        path: 'reset-password',
                        children: [
                            {
                                index: true,
                                element: <ResetPasswordPage />,
                            },
                            {
                                path: ':email',
                                element: <ResetPasswordPage />,
                            },
                        ],
                    },
                    {
                        path: 'new-password-required',
                        element: <NewPasswordRequiredPage />,
                    },
                ],
            },
            {
                path: 'start',
                element: <Start />,
            },
            {
                path: 'dates',
                element: <Dates />,
            },
            {
                path: 'go',
                children: [
                    {
                        index: true,
                        element: <Go />,
                    },
                    {
                        path: 'friends',
                        element: <Friends />,
                    },
                    {
                        path: 'romantic',
                        element: <Romantic />,
                    },
                    {
                        path: 'group',
                        element: <Group />,
                    },
                ],
            },
        ],
    },
])

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <ToastContainer />
            <CookieConsent />
            <RouterProvider router={router} />
        </QueryClientProvider>
    </React.StrictMode>
)
