import { FormEvent, useCallback, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useAuth } from '@/contexts'
import { Button, Input } from '../common'

export function ConfirmEmailForm() {
    const { confirmEmail, resendCode } = useAuth()
    const { email: emailFromQuery } = useParams()

    const [email, setEmail] = useState(emailFromQuery || '')
    const [code, setCode] = useState('')

    const handleSubmit = useCallback(
        (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault()

            confirmEmail(email, code)
        },
        [confirmEmail, email, code]
    )

    const handleResendCode = useCallback(() => {
        resendCode(email)
    }, [resendCode, email])

    return (
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
            <Link to="/" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                <img src="/logo.svg" alt="Logo" className="w-80" />
            </Link>
            <div className="w-full bg-white shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">Confirm your Email</h1>
                    <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                        <Input
                            label="Your email"
                            type="email"
                            name="email"
                            id="email"
                            aria-describedby="email"
                            autoComplete="email"
                            placeholder="name@company.com"
                            value={email}
                            setValue={setEmail}
                            disabled={!!emailFromQuery}
                            required
                        />

                        <Input
                            label="Code"
                            name="code"
                            id="code"
                            aria-describedby="code"
                            placeholder="123456"
                            autoComplete="code"
                            value={code}
                            setValue={setCode}
                            required
                        />

                        <Button variant="link" onClick={handleResendCode} label="Resend code" />

                        <Button type="submit" label="Confirm Email" />

                        <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                            Sie haben bereits ein Konto?{' '}
                            <Link to="/auth/sign-in" className="font-medium text-pink-600 hover:underline dark:text-pink-600">
                                Hier anmelden
                            </Link>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    )
}
