import { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useForm } from 'react-hook-form'
import { RadioInput } from '../common/RadioInput'
import { StepContent } from './StepContent'
import { useDispatch } from 'react-redux'
import { isEmptyAnswer } from '@namuho/helpers'
import { CreatorSteps, markStepCompletedByName, nextStep } from '@/slices/creator'
import { CreatorButtons } from './CreatorButtons'
import clsx from 'clsx'
import { CheckboxesInput } from '../common/CheckboxesInput'
import { usePreferences } from '@/hooks/preferences/usePreferences'
import { AlcoholConsumption, BodyType, IPersonalDetails, Pet, SmokingConsumption } from '@namuho/types'
import { Divider } from '@mui/material'
import { HeightInput } from '../common/HeightInput'
import { StepTitle } from './StepTitle'

type ClassKeys = void | 'root' | 'loading'

const useStyles = makeStyles<ClassKeys>()((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(2),
    },
    divider: {
        width: '100%',
        borderBottom: `1px dotted ${theme.palette.primary.main}`,
    },
    heightLabel: {
        display: 'grid',
        gridTemplateColumns: '1fr 3fr',
        alignItems: 'center',
        border: 'none',
        gap: theme.spacing(2),
        width: '100%',
        padding: 0,
        margin: 0,
        marginTop: theme.spacing(2),
        textAlign: 'left',
        minWidth: 0,
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: theme.spacing(2),
            gap: theme.spacing(4),
        },
    },
    sliderLabel: {
        display: 'block',
        width: '100%',
        textAlign: 'left',
    },
    loading: {
        opacity: '50%',
    },
}))

export const PersonalDetails = () => {
    const { register, getValues, setValue, watch } = useForm<IPersonalDetails>({
        defaultValues: {
            height: 170,
            pets: [],
        },
    })
    const { classes } = useStyles()
    const dispatch = useDispatch()
    const [loading, setIsLoading] = useState(false)
    const { personalDetails, updatePersonalDetails, isLoading: isLoadingPreferences } = usePreferences()
    const allFieldsAnswered = !Object.values(watch()).find((value) => isEmptyAnswer(value))

    const handleNext = async () => {
        setIsLoading(true)
        const values = getValues()
        updatePersonalDetails(values)
        setIsLoading(false)
        dispatch(markStepCompletedByName(CreatorSteps.PERSONAL_DETAILS))
        dispatch(nextStep())
    }

    useEffect(() => {
        try {
            if (personalDetails) {
                Object.keys(personalDetails).forEach((key) => {
                    setValue(key as keyof IPersonalDetails, personalDetails[key])
                })
            }
        } catch (e) {
            console.log(e)
        }
    }, [personalDetails, setValue])

    const handleHeightChange = (value: number | number[]) => {
        if (typeof value === 'number') {
            setValue('height', value)
        }
    }

    return (
        <StepContent
            title={<StepTitle title="Infos über dich" />}
            content={
                <form className={clsx(classes.root, loading && classes.loading)}>
                    <RadioInput
                        label="Rauchst du?"
                        options={(Object.values(SmokingConsumption) as Array<SmokingConsumption>).map((type) => ({
                            label: type,
                            value: type,
                        }))}
                        {...register('smokingConsumption')}
                    />
                    <Divider className={classes.divider} />
                    <RadioInput
                        label="Deine Figur?"
                        options={(Object.values(BodyType) as Array<BodyType>).map((type) => ({
                            label: type,
                            value: type,
                        }))}
                        {...register('bodyType')}
                    />
                    <Divider className={classes.divider} />
                    <label className={classes.heightLabel} htmlFor="height">
                        <span className={classes.sliderLabel}>Deine Größe</span>
                        <HeightInput handleChange={handleHeightChange} value={getValues('height')} />
                    </label>
                    <Divider className={classes.divider} />
                    <RadioInput
                        label="Dein Alkoholkonsum?"
                        options={(Object.values(AlcoholConsumption) as Array<AlcoholConsumption>).map((type) => ({
                            label: type,
                            value: type,
                        }))}
                        {...register('alcoholConsumption')}
                    />
                    <Divider className={classes.divider} />
                    <RadioInput
                        label="Hast du Kinder?"
                        options={[
                            { value: 'true', label: 'Ja' },
                            { value: 'false', label: 'Nein' },
                        ]}
                        {...register('kids')}
                    />
                    <Divider className={classes.divider} />
                    <CheckboxesInput
                        label="Hast du Haustiere?"
                        options={(Object.values(Pet) as Array<Pet>).map((type) => ({
                            label: type,
                            value: type,
                        }))}
                        {...register('pets')}
                    />
                    <Divider className={classes.divider} />
                </form>
            }
            buttons={
                <CreatorButtons
                    nextStepActive={allFieldsAnswered}
                    previousStepActive={true}
                    isFirst={false}
                    isLast={false}
                    skipActive={true}
                    handleNext={handleNext}
                />
            }
        />
    )
}
