import ContentLoader from 'react-content-loader'

export const SecretsSkeleton = () => (
    <ContentLoader speed={2} width={600} height={350} viewBox="0 0 800 350" backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
        <rect x="0" y="0" rx="3" ry="3" width="800" height="50" />
        <rect x="0" y="60" rx="3" ry="3" width="800" height="50" />
        <rect x="0" y="120" rx="3" ry="3" width="800" height="50" />
        <rect x="0" y="180" rx="3" ry="3" width="800" height="50" />
        <rect x="0" y="240" rx="3" ry="3" width="800" height="50" />
        <rect x="0" y="300" rx="3" ry="3" width="800" height="50" />
    </ContentLoader>
)
