import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { City, Country, Gender } from '@namuho/types'

export interface NewUser {
    email: string | undefined
    firstName: string | undefined
    lastName: string | undefined
    birthDate: string | undefined
    gender: Gender | undefined
    country: Country | undefined
    password: string | undefined
    city?: City
    silentSignIn: 'YES' | 'NO' | 'CONFIRMED' | 'PENDING' | 'WRONG_PASSWORD' | 'OTHER_ERROR'
    confirmed: boolean
    privacy?: string
}

const initialState: NewUser = {
    email: '',
    firstName: '',
    lastName: '',
    gender: undefined,
    country: Country.Germany,
    city: City.Aachen,
    birthDate: undefined,
    password: undefined,
    silentSignIn: 'NO', // did we successfully log in after confirmation
    confirmed: false,
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload
        },
        setUserData: (state, action: PayloadAction<Partial<NewUser>>) => {
            const { email, firstName, lastName, birthDate, gender, country, password } = action.payload
            return {
                ...state,
                email,
                firstName,
                lastName,
                birthDate,
                gender,
                country,
                password,
            }
        },
        setCity: (state, action: PayloadAction<City>) => {
            state.city = action.payload
        },
        clearUserData: () => {
            return initialState
        },
        setSilentSignIn: (state, action: PayloadAction<'YES' | 'NO' | 'CONFIRMED' | 'PENDING' | 'WRONG_PASSWORD' | 'OTHER_ERROR'>) => {
            state.silentSignIn = action.payload
        },
        setUserConfirmed: (state) => {
            state.confirmed = true
        },
    },
})

// Action creators are generated for each case reducer function
export const { setEmail, setCity, setUserData, clearUserData, setSilentSignIn, setUserConfirmed } = userSlice.actions

export default userSlice.reducer
