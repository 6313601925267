import { Logo } from '../Logo'
import { makeStyles } from 'tss-react/mui'
import { Typography } from '@mui/material'

const useStyles = makeStyles()((theme) => ({
    root: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(2),
        color: theme.palette.common.white,
    },
    logo: {
        width: 300,
    },
}))
export const LoadingPage = () => {
    const { classes } = useStyles()
    return (
        <main className={classes.root}>
            <Logo className={classes.logo} />
            <Typography variant="h1">Ein Moment bitte</Typography>
            <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </main>
    )
}
