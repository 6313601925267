import { ChangeEvent, InputHTMLAttributes, useCallback } from 'react'

export interface SecretCheckBoxProps extends InputHTMLAttributes<HTMLInputElement> {
    label: string
    checked: boolean
    secretId: string
    setChecked: (checked: boolean, secretId: string) => void
}

export function SecretCheckbox({ label, checked, setChecked, secretId, id, ...restProps }: SecretCheckBoxProps) {
    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setChecked(event.target.checked, secretId)
        },
        [setChecked, secretId]
    )

    return (
        <label htmlFor={id} className="flex flex-wrap gap-4 items-center">
            <input
                id={id}
                type="checkbox"
                className="w-4 h-4 border border-gray-300 bg-gray-50 focus:ring-3 focus:ring-pink-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-pink-600 dark:ring-offset-gray-800"
                checked={checked}
                onChange={handleChange}
                {...restProps}
            />
            <span className="text-gray-900 dark:text-white">{label}</span>
        </label>
    )
}
