import { Box, Grid, IconButton, Paper, Typography } from '@mui/material'
import { ChevronLeft as BackIcon } from '@mui/icons-material'
import { makeStyles } from 'tss-react/mui'
import { Link, useNavigate } from 'react-router-dom'
import { MeetingsTypeSection } from '@/components/meetings/MeetingsTypeSection'
import { Footer } from '@/components/nav/Footer'
import { MeetingType } from '@namuho/types'
import { useAuth } from '@/contexts'
import { useEffect } from 'react'

type ClassKeys = void | 'root' | 'contaier'

const useStyles = makeStyles<ClassKeys>()((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: theme.palette.background.romantic,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    container: {
        marginTop: theme.spacing(2),
        width: '90vw',
        maxWidth: '100%',
        padding: theme.spacing(2),
        backgroundColor: theme.palette.common.white,
    },
    back: {
        alignSelf: 'flex-start',
        marginLeft: theme.spacing(4),
    },
    title: {
        [theme.breakpoints.down('md')]: {
            margin: theme.spacing(2),
        },
    },
}))

export interface StartProps {
    className?: string
}

export function Go(props: StartProps) {
    const { classes } = useStyles()
    const { user, loading } = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        if (!loading && !user) {
            navigate('/auth/sign-in', { replace: true })
        }
    }, [user, loading, navigate])

    return (
        <>
            <Box component="main" className={classes.root} {...props}>
                <IconButton component={Link} className={classes.back} to="/">
                    <BackIcon />
                    Zurück
                </IconButton>
                <Typography className={classes.title} variant="h1">
                    Wähle eine Art des Treffen
                </Typography>
                <Paper className={classes.container}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <MeetingsTypeSection
                                title="Bevorstehende Dates"
                                link="/go/romantic"
                                dateName="Dates"
                                meetingType={MeetingType.Romantic}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <MeetingsTypeSection
                                title="Bevorstehende Treffen"
                                link="/go/friends"
                                dateName="Treffen"
                                meetingType={MeetingType.Friendly}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <MeetingsTypeSection
                                title="Bevorstehende Gruppentreffen"
                                link="/go/group"
                                dateName="Gruppentreffen"
                                meetingType={MeetingType.Group}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
            <Footer />
        </>
    )
}
