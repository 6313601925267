import React from 'react'
import { Paper, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

export interface BlockProps {
    title: string
    children: React.ReactNode
    secondary?: boolean
}

type ClassKeys = void | 'root'

const useStyles = makeStyles<ClassKeys>()((theme) => ({
    root: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.common.white,
    },
}))

export function Block(props: BlockProps) {
    const { children, title, secondary = false } = props
    const { classes } = useStyles()
    return (
        <Paper square className={classes.root}>
            <Typography variant={secondary ? 'h2' : 'h1'}>{title}</Typography>
            <div>{children}</div>
        </Paper>
    )
}
