import { useEffect, useState } from 'react'
import { Button, IconButton, Skeleton } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { NavSection } from './NavSection'
import { clearUserData } from '@/slices/user'
import { useDispatch } from 'react-redux'
import { resetSteps } from '@/slices/creator'
import { makeStyles } from 'tss-react/mui'
import { useAuth } from '@/contexts'
import { ArrowLeft } from '@mui/icons-material'

const useStyles = makeStyles()((theme) => ({
    root: {
        marginBottom: theme.spacing(4),
    },
    button: {
        justifyContent: 'start',
        color: theme.palette.common.white,
    },
}))

export interface UserProps {
    isNotDashbaord?: boolean
}

export function User(props: UserProps) {
    const { isNotDashbaord = false } = props
    const [userName, setUserName] = useState<string | null>(null)
    const [isLoading, setIsLoading] = useState(true)
    const { classes } = useStyles()
    const dispatch = useDispatch()

    const { signOut, userAttributes } = useAuth()
    const navigate = useNavigate()

    function logOut() {
        signOut()
        dispatch(clearUserData())
        dispatch(resetSteps())
        navigate('/auth/sign-in')
    }

    const navigateToDashboard = () => {
        navigate('/')
    }
    useEffect(() => {
        const fetchUser = async () => {
            const name = userAttributes?.given_name || 'Freunde'
            setUserName(name)
            setIsLoading(false)
        }
        fetchUser()
    }, [userAttributes?.given_name])

    return (
        <>
            {isLoading && (
                <div className={classes.root}>
                    <Skeleton width="250" height="50" />
                    <Skeleton width="250" height="50" />
                </div>
            )}
            {userName && (
                <NavSection title={`Hey {${userName}}`}>
                    <Button variant="text" className={classes.button} onClick={logOut}>
                        Ausloggen
                    </Button>
                    {isNotDashbaord && (
                        <Button variant="text" className={classes.button} onClick={navigateToDashboard}>
                            Dashboard
                        </Button>
                    )}
                </NavSection>
            )}
        </>
    )
}
