import { Info } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'

export const GendersInfoIcon = () => {
    return (
        <Tooltip
            enterTouchDelay={100}
            title='Du hast die Möglichkeit, zwischen den Optionen "weiblich" und "männlich" zu wählen oder beide gleichzeitig auszuwählen. Wenn du dich für "jedes Geschlecht" entscheidest, besteht auch die Möglichkeit, mit Personen gematcht zu werden, die ihre Geschlechtsidentität nicht auf "männlich" oder "weiblich" festgelegt haben. Du hast außerdem die Freiheit, alle drei Optionen gleichzeitig zu wählen.'
        >
            <IconButton>
                <Info color="primary" />
            </IconButton>
        </Tooltip>
    )
}
