import { useEffect } from 'react'
import { Box, Step, StepLabel, Stepper } from '@mui/material'
import { stepLabelClasses } from '@mui/material/StepLabel'
import type { RootState } from '@/store'
import { useSelector, useDispatch } from 'react-redux'
import { CreatorGoSteps, goToStepNumber, goToPaymentStep, goToStepById } from '@/slices/creatorGo'
import { SecretsGo } from './SecretsGo'
import { BasicQuestionsGo } from './BasicQuestionsGo'
import { PersonalDetailsGo } from './PersonalDetailsGo'
import { RatedQuestionsGo } from './RatedQuestionsGo'
import { NoGosAnswersGo } from './NoGosAnswersGo'
import { DatesGo } from './DatesGo'
import { ProductsGo } from './ProductsGo'
import { markStepCompleted } from '@/slices/creatorGo'
import useWindowSize from '@/helpers/useWindowSize'
import { StepIcon } from '../common/stepper/StepIcon'
import { StepConnectorNamuho } from '../common/stepper/StepConnectorNamuho'
import { makeStyles } from 'tss-react/mui'
import { useReadiness } from '@/hooks/user/useReadiness'
import { MeetingType } from '@namuho/types'
import { useSearchParams } from 'react-router-dom'

export interface CreatorGoProps {
    dateType: MeetingType
}

const useStyles = makeStyles()((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
        },
    },
    step: {
        padding: 0,
    },
    stepLabel: {
        padding: 0,
        [`& .${stepLabelClasses.iconContainer}`]: {
            paddingRight: 0,
        },
    },
}))

export function CreatorGo(props: CreatorGoProps) {
    const { dateType } = props
    const { classes } = useStyles()
    const activeStepIndex = useSelector((state: RootState) => state.creatorGo[dateType].activeStepIndex)
    const activeStep = useSelector((state: RootState) => {
        const index = state.creatorGo[dateType].activeStepIndex
        return state.creatorGo[dateType].steps.find((step) => step.index === index)?.id || CreatorGoSteps.BASIC_QUESTIONS
    })
    const steps = useSelector((state: RootState) => state.creatorGo[dateType].steps)
    const dispatch = useDispatch()
    const { isMobile } = useWindowSize()
    const [searchParams, setSearchParams] = useSearchParams()
    const { [MeetingType.Romantic]: romantic, [MeetingType.Friendly]: friendly, [MeetingType.Group]: group, personalDetailsAnswered } = useReadiness()

    const handleStep = (stepIndex: number) => () => {
        setSearchParams({})
        dispatch(goToStepNumber({ dateType: dateType, number: stepIndex }))
    }

    useEffect(() => {
        const dictionary = {
            [MeetingType.Romantic]: romantic,
            [MeetingType.Friendly]: friendly,
            [MeetingType.Group]: group,
        }
        const readiness = dictionary[dateType]
        if (readiness?.basicAnswered) {
            dispatch(markStepCompleted({ dateType: dateType, step: CreatorGoSteps.BASIC_QUESTIONS }))
        }
        if (personalDetailsAnswered) {
            dispatch(markStepCompleted({ dateType: dateType, step: CreatorGoSteps.NOGOS_ME }))
        }
        if (readiness?.weightedAnswered) {
            dispatch(markStepCompleted({ dateType: dateType, step: CreatorGoSteps.RATED_QUESTIONS }))
        }
        if (readiness?.secretsAnswered) {
            dispatch(markStepCompleted({ dateType: dateType, step: CreatorGoSteps.SECRETS }))
        }
        if (readiness?.noGosSeen) {
            dispatch(markStepCompleted({ dateType: dateType, step: CreatorGoSteps.NOGOS_YOU }))
        }
        if (readiness?.slotsBought) {
            dispatch(markStepCompleted({ dateType: dateType, step: CreatorGoSteps.PRODUCTS }))
        }
        if (readiness?.timeSlotsAvailable) {
            dispatch(markStepCompleted({ dateType: dateType, step: CreatorGoSteps.DATES }))
        }
    }, [activeStepIndex, dateType, dispatch, friendly, group, personalDetailsAnswered, romantic])

    useEffect(() => {
        if (searchParams.get('paymentCallback')) {
            dispatch(goToPaymentStep(dateType))
        }
        if (searchParams.get('step')) {
            dispatch(goToStepById({ dateType, step: searchParams.get('step') as CreatorGoSteps }))
        }
    }, [dateType, dispatch, searchParams, steps])
    const stepperType = isMobile ? 'vertical' : 'horizontal'

    return (
        <Box className={classes.root}>
            <Stepper nonLinear orientation={stepperType} activeStep={activeStepIndex} connector={<StepConnectorNamuho />}>
                {steps.map((step) => {
                    return (
                        <Step className={classes.step} completed={steps[step.index]?.completed} key={(step.index + 1).toString()}>
                            <StepLabel className={classes.stepLabel} onClick={handleStep(step.index)} StepIconComponent={StepIcon}></StepLabel>
                        </Step>
                    )
                })}
            </Stepper>
            {activeStep === CreatorGoSteps.BASIC_QUESTIONS && <BasicQuestionsGo dateType={dateType} />}
            {activeStep === CreatorGoSteps.NOGOS_ME && <PersonalDetailsGo dateType={dateType} />}
            {activeStep === CreatorGoSteps.NOGOS_YOU && <NoGosAnswersGo dateType={dateType} />}
            {activeStep === CreatorGoSteps.RATED_QUESTIONS && <RatedQuestionsGo dateType={dateType} />}
            {activeStep === CreatorGoSteps.SECRETS && <SecretsGo dateType={dateType} />}
            {activeStep === CreatorGoSteps.PRODUCTS && <ProductsGo dateType={dateType} />}
            {activeStep === CreatorGoSteps.DATES && <DatesGo dateType={dateType} />}
        </Box>
    )
}
