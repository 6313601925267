import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface MeetingsState {
    activeDate: string | undefined
}

const initialState: MeetingsState = {
    activeDate: undefined,
}

export const meetingsSlice = createSlice({
    name: 'meetings',
    initialState,
    reducers: {
        setActiveDate: (state, action: PayloadAction<string | undefined>) => {
            state.activeDate = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { setActiveDate } = meetingsSlice.actions

export default meetingsSlice.reducer
