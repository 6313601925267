import { configureStore } from '@reduxjs/toolkit'
import userReducer from './slices/user'
import creatorReducer from './slices/creator'
import creatorGoReducer from './slices/creatorGo'
import meetingsReducer from './slices/meetings'

export const store = configureStore({
    reducer: {
        createdUser: userReducer,
        creator: creatorReducer,
        creatorGo: creatorGoReducer,
        meetings: meetingsReducer,
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
