import clsx from 'clsx'
import { makeStyles } from 'tss-react/mui'
import { Logo } from '../Logo'

const useStyles = makeStyles()((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        padding: theme.spacing(4),
        backgroundColor: theme.palette.secondary.main,
        minHeight: 170,
        zIndex: theme.zIndex.drawer,
    },
    links: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        listStyle: 'none',
        color: theme.palette.common.white,
        gap: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    icons: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(4),
    },
    social: {
        display: 'flex',
        gap: theme.spacing(2),
    },
    logo: {
        color: theme.palette.common.white,
        width: 200,
        height: 'auto',
    },
    instagram: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 50,
        height: 50,
        backgroundColor: '#262626',
        borderRadius: '10%',
    },
    facebook: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 50,
        height: 50,
        backgroundColor: '#3b5998',
        borderRadius: '10%',
    },
}))

export function Footer(props: { className?: string }) {
    const { className } = props
    const { classes } = useStyles()
    return (
        <footer className={clsx(classes.root, className)}>
            <ul className={classes.links}>
                <li>
                    <a href="https://namuho.com/anleitung/">Anleitung</a>
                </li>
                <li>
                    <a href="https://namuho.com/faq/">FAQ</a>
                </li>
                <li>
                    <a href="https://namuho.com/guideline/">Guideline</a>
                </li>
                <li>
                    <a href="https://namuho.com/tipps-hinweise/">Tipps & Hinweise</a>
                </li>
            </ul>
            <ul className={classes.links}>
                <li>
                    <a href="https://namuho.com/impressum/">Impressum</a>
                </li>
                <li>
                    <a href="https://namuho.com/datenschutzerklaerung/">Datenschutzerklärung</a>
                </li>
                <li>
                    <a href="https://namuho.com/agb/">AGB</a>
                </li>
            </ul>
            <div className={classes.icons}>
                <a href="https://namuho.com">
                    <Logo className={classes.logo} />
                </a>
                <ul className={classes.social}>
                    <li>
                        <a className={classes.instagram} href="https://www.instagram.com/namuhoconnect/?igshid=MzNlNGNkZWQ4Mg%3D%3D">
                            <img src="/instagram.svg" alt="instagram" />
                        </a>
                    </li>
                    <li>
                        <a className={classes.facebook} href="https://www.facebook.com/profile.php?id=100093296198913">
                            <img src="/facebook.svg" alt="facebook" />
                        </a>
                    </li>
                </ul>
            </div>
        </footer>
    )
}
