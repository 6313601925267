import { Stripe } from 'stripe'
import { MeetingType } from '.'

export interface IProductMetadata {
    meetingType: MeetingType | null
    bonus: boolean
    dates: number
    extraDates: number
    productType: ProductType
    productName: string
}
export interface IProduct extends Stripe.Product {
    default_price: Stripe.Price
}

export enum ProductType {
    Date = 'date',
    AllSecrets = 'all_secrets',
    SingleSecret = 'single_secret',
}
