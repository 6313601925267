import { useEffect, useMemo, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useForm } from 'react-hook-form'
import { Slider, Typography } from '@mui/material'
import { StepContent } from './StepContent'
import { CreatorButtons } from './CreatorButtons'
import { useDispatch, useSelector } from 'react-redux'
import { nextStep, markStepCompletedByName, CreatorSteps, setError } from '@/slices/creator'
import { RootState } from '@/store'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { usePreferences } from '@/hooks/preferences/usePreferences'
import { Gender, IPreferences, MeetingType } from '@namuho/types'
import { SliderTooltip } from '../common/SliderTooltip'
import { ageMarks } from '@/helpers/ageMarks'
import useWindowSize from '@/helpers/useWindowSize'
import { CheckboxesInput } from '../common/CheckboxesInput'
import { GendersInfoIcon } from '../common/GendersInfoIcon'

const useStyles = makeStyles()((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(2),
    },
    loading: {
        opacity: '50%',
    },
    ageLabel: {
        display: 'grid',
        gridTemplateColumns: '1fr 3fr',
        alignItems: 'center',
        border: 'none',
        gap: theme.spacing(2),
        width: '100%',
        padding: 0,
        margin: 0,
        marginTop: theme.spacing(2),
        textAlign: 'left',
        minWidth: 0,
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: theme.spacing(2),
            gap: theme.spacing(4),
        },
    },
    stepTitle: {
        display: 'flex',
        gap: theme.spacing(1),
        alignItems: 'center',
        textAlign: 'left',
        marginBottom: theme.spacing(2),
    },
    sliderLabel: {
        display: 'block',
        textAlign: 'left',
        width: '100%',
    },
    markLabel: {
        fontSize: '0.9rem',
        color: 'silver',
    },
}))

const defaultValues: BasicQuestionsForm = {
    prefferedGender: [],
    prefferedAge: [18, 99],
}

export interface BasicQuestionsForm {
    prefferedGender: Gender[]
    prefferedAge: number[]
}

export const BasicQuestions = () => {
    const { register, watch, getValues, setValue } = useForm<BasicQuestionsForm>({
        defaultValues,
    })
    const { classes } = useStyles()
    const dispatch = useDispatch()
    const chosenDateType = useSelector((state: RootState) => state.creator.chosenDateType)
    const {
        isLoading: isLoadingPreferences,
        friendlyPreferences,
        romanticPreferences,
        groupPreferences,
        updateGroupPreferences,
        updateFriendlyPreferences,
        updateRomanticPreferences,
    } = usePreferences()

    const [loading, setLoading] = useState(false)
    const { t } = useTranslation()
    const { isMobile } = useWindowSize()

    const ageRangeValue = watch('prefferedAge')

    useEffect(() => {
        register('prefferedAge')
    }, [register])

    const genders = useMemo(() => {
        return [
            {
                label: t('genders.Male'),
                value: Gender.Male,
            },
            {
                label: t('genders.Female'),
                value: Gender.Female,
            },
            {
                label: t('genders.All'),
                value: Gender.Other,
            },
        ]
    }, [t])
    useEffect(() => {
        if (chosenDateType === MeetingType.Friendly) {
            if (friendlyPreferences?.prefferedAge) {
                const preferedAgeTransformed = [friendlyPreferences?.prefferedAge?.min, friendlyPreferences?.prefferedAge?.max]
                setValue('prefferedAge', preferedAgeTransformed)
            }
            if (friendlyPreferences?.prefferedGender) {
                setValue('prefferedGender', friendlyPreferences?.prefferedGender || [Gender.Other])
            }
        }
        if (chosenDateType === MeetingType.Romantic) {
            if (romanticPreferences?.prefferedAge) {
                const preferedAgeTransformed = [romanticPreferences?.prefferedAge?.min, romanticPreferences?.prefferedAge?.max]
                setValue('prefferedAge', preferedAgeTransformed)
            }
            if (romanticPreferences?.prefferedGender) {
                setValue('prefferedGender', romanticPreferences?.prefferedGender || [Gender.Other])
            }
        }
        if (chosenDateType === MeetingType.Group) {
            if (groupPreferences?.prefferedAge) {
                const preferedAgeTransformed = [groupPreferences?.prefferedAge?.min, groupPreferences?.prefferedAge?.max]
                setValue('prefferedAge', preferedAgeTransformed)
            }
            if (groupPreferences?.prefferedGender) {
                setValue('prefferedGender', groupPreferences?.prefferedGender || [Gender.Other])
            }
        }
    }, [
        chosenDateType,
        friendlyPreferences?.prefferedAge,
        friendlyPreferences?.prefferedGender,
        genders,
        groupPreferences?.prefferedAge,
        groupPreferences?.prefferedGender,
        romanticPreferences?.prefferedAge,
        romanticPreferences?.prefferedGender,
        setValue,
    ])

    const handleAgeChange = (newValue: number | number[]) => {
        setValue('prefferedAge', newValue as [number, number])
    }

    useEffect(() => {
        const subscription = watch(async () => {
            dispatch(setError({ error: false, message: '' }))
        })
        return () => subscription.unsubscribe()
    }, [dispatch, watch])

    const handleNext = async () => {
        setLoading(true)
        if (getValues('prefferedGender').length === 0) {
            dispatch(setError({ error: true, message: 'Bitte wähle ein Geschlecht' }))
        }

        if (chosenDateType === MeetingType.Friendly) {
            const updateObject = {
                ...friendlyPreferences,
                prefferedAge: {
                    min: ageRangeValue[0],
                    max: ageRangeValue[1],
                },
                prefferedGender: getValues('prefferedGender'),
            }
            updateFriendlyPreferences(updateObject as IPreferences)
        }
        if (chosenDateType === MeetingType.Romantic) {
            const updateObject = {
                ...romanticPreferences,
                prefferedAge: {
                    min: ageRangeValue[0],
                    max: ageRangeValue[1],
                },
                prefferedGender: getValues('prefferedGender'),
            }
            updateRomanticPreferences(updateObject as IPreferences)
        }
        if (chosenDateType === MeetingType.Group) {
            const updateObject = {
                ...groupPreferences,
                prefferedAge: {
                    min: ageRangeValue[0],
                    max: ageRangeValue[1],
                },
                prefferedGender: getValues('prefferedGender'),
            }
            updateGroupPreferences(updateObject as IPreferences)
        }
        setLoading(false)
        dispatch(markStepCompletedByName(CreatorSteps.BASIC_QUESTIONS))
        dispatch(nextStep())
    }

    const Title = () => {
        return (
            <div className={classes.stepTitle}>
                <Typography variant="h2">Wen würdest du gerne treffen?</Typography>
                <GendersInfoIcon />
            </div>
        )
    }

    return (
        <StepContent
            title={<Title />}
            content={
                <form className={clsx(classes.root, loading && classes.loading)}>
                    <CheckboxesInput
                        label="Mit welchem Geschlecht"
                        options={genders.map((type) => ({
                            label: type.label,
                            value: type.value,
                        }))}
                        {...register('prefferedGender')}
                    />
                    <label className={classes.ageLabel} htmlFor="ageRangeValue">
                        <span className={classes.sliderLabel}>Welche Altersklasse</span>
                        <Slider
                            onChange={(_, value) => {
                                handleAgeChange(value)
                            }}
                            classes={{ markLabel: classes.markLabel }}
                            value={ageRangeValue}
                            valueLabelDisplay={isMobile ? 'auto' : 'on'}
                            slots={
                                isMobile
                                    ? undefined
                                    : {
                                          valueLabel: (props) => <SliderTooltip {...props} labelSuffix="Jahre" />,
                                      }
                            }
                            marks={ageMarks}
                            min={18}
                            max={99}
                            step={1}
                        />
                    </label>
                </form>
            }
            buttons={
                <CreatorButtons
                    nextStepActive={true}
                    previousStepActive={true}
                    isFirst={false}
                    isLast={false}
                    skipActive={true}
                    handleNext={handleNext}
                />
            }
        />
    )
}
