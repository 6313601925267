import { HTMLProps, useEffect, useState } from 'react'
import { Button } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { TextInput } from './common/TextInput'
import clsx from 'clsx'
import { RadioInput } from './common/RadioInput'
import { useForm } from 'react-hook-form'
import { AlcoholConsumption, BodyType, IPersonalDetails, Pet, SmokingConsumption } from '@namuho/types'
import { usePreferences } from '@/hooks/preferences/usePreferences'
import { CheckboxesInput } from './common/CheckboxesInput'

type ClassKeys = void | 'root' | 'editButton' | 'buttons'

const useStyles = makeStyles<ClassKeys>()((theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        alignItems: 'stretch',
        justifyContent: 'center',
    },
    editButton: {
        marginTop: theme.spacing(2),
        alignSelf: 'flex-end',
    },
    buttons: {
        marginLeft: 'auto',
        display: 'flex',
        gap: theme.spacing(1),
    },
    loading: {
        opacity: '50%',
    },
}))

export const PersonalDetailsDashboard = (props: HTMLProps<HTMLDivElement>) => {
    const { register, setValue, getValues } = useForm<IPersonalDetails>({
        defaultValues: {
            pets: [],
        },
    })
    const { classes } = useStyles()
    const [isEdited, setIsEdited] = useState(false)
    const [loading, setIsLoading] = useState(false)
    const { personalDetails, updatePersonalDetails, isLoading: isLoadingPreferences } = usePreferences()

    const handleEdit = () => {
        setIsEdited(true)
    }

    const handleCancel = () => {
        setIsEdited(false)
    }

    const handleSave = async () => {
        setIsLoading(true)
        const values = getValues()
        updatePersonalDetails(values)
        setIsEdited(false)
        setIsLoading(false)
    }

    useEffect(() => {
        try {
            if (personalDetails) {
                Object.keys(personalDetails).forEach((key) => {
                    setValue(key as keyof IPersonalDetails, personalDetails[key])
                })
            }
        } catch (e) {
            console.log(e)
        }
    }, [personalDetails, setValue])

    return (
        <div {...props} className={clsx(classes.root, loading && classes.loading)}>
            <div>
                <form className={clsx(classes.root)}>
                    <RadioInput
                        disabled={!isEdited}
                        label="Rauchst du?"
                        options={(Object.values(SmokingConsumption) as Array<SmokingConsumption>).map((type) => ({
                            label: type,
                            value: type,
                        }))}
                        {...register('smokingConsumption')}
                    />
                    <RadioInput
                        disabled={!isEdited}
                        label="Deine Figur?"
                        options={(Object.values(BodyType) as Array<BodyType>).map((type) => ({
                            label: type,
                            value: type,
                        }))}
                        {...register('bodyType')}
                    />
                    <TextInput disabled={!isEdited} label="Deine Größe?" type="number" {...register('height')} />
                    <RadioInput
                        disabled={!isEdited}
                        label="Dein Alkoholkonsum?"
                        options={(Object.values(AlcoholConsumption) as Array<AlcoholConsumption>).map((type) => ({
                            label: type,
                            value: type,
                        }))}
                        {...register('alcoholConsumption')}
                    />
                    <RadioInput
                        disabled={!isEdited}
                        label="Hast du Kinder?"
                        options={[
                            { value: 'true', label: 'Ja' },
                            { value: 'false', label: 'Nein' },
                        ]}
                        {...register('kids')}
                    />
                    <CheckboxesInput
                        disabled={!isEdited}
                        label="Hast du Haustiere?"
                        options={(Object.values(Pet) as Array<Pet>).map((type) => ({
                            label: type,
                            value: type,
                        }))}
                        {...register('pets')}
                    />
                </form>
            </div>
            {!isEdited && (
                <Button className={classes.editButton} variant="contained" color="secondary" onClick={handleEdit}>
                    Bearbeiten
                </Button>
            )}
            {isEdited && (
                <div className={classes.buttons}>
                    <Button className={classes.editButton} variant="contained" color="secondary" onClick={handleCancel}>
                        Stornieren
                    </Button>
                    <Button className={classes.editButton} variant="contained" color="primary" onClick={handleSave}>
                        Speichern
                    </Button>
                </div>
            )}
        </div>
    )
}
