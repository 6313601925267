import { ChangeEvent, useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { StepContent } from './StepContent'
import { NoGoQuestion } from './NoGoQuestion'
import { noGoQuestions } from './NoGosQuestions'
import { markStepCompleted, nextStep, setError } from '@/slices/creator'
import { useDispatch, useSelector } from 'react-redux'
import { CreatorButtons } from './CreatorButtons'
import { useForm } from 'react-hook-form'
import { RootState } from '@/store'
import clsx from 'clsx'
import { RadioInput } from '../common/RadioInput'
import { CheckboxesInput } from '../common/CheckboxesInput'
import { NoGos_MeForm } from '@/types/Questions'
import { usePreferences } from '@/hooks/preferences/usePreferences'
import { BodyType, IPreferences, Pet } from '@namuho/types'
import { HeightInput } from '../common/HeightInput'
import { Alert, Button } from '@mui/material'
import { StepTitle } from './StepTitle'

const useStyles = makeStyles()((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(2),
    },
    loading: {
        opacity: '50%',
    },
    input: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: theme.spacing(2),
        gridColumn: 'span 2',
    },
    alert: {
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
}))

export const NoGosAnswers = () => {
    const { classes } = useStyles()
    const dispatch = useDispatch()
    const [activeQuestionsIds, setActiveQuestionsIds] = useState<string[]>([])
    const [warningDissmissed, setWarningDissmissed] = useState(false)
    const [showWarning, setShowWarning] = useState(false)
    const chosenDateType = useSelector((state: RootState) => state.creator.chosenDateType)
    const { register, getValues, setValue, watch } = useForm<NoGos_MeForm>({})
    const { isLoading: loading, romanticPreferences, updateRomanticPreferences } = usePreferences()
    const sizeMax = watch('sizeMax')
    const sizeMin = watch('sizeMin')

    const handleWarningDismiss = () => {
        setWarningDissmissed(true)
    }

    const handleChangeSizeMax = (value: number | number[]) => {
        if (typeof value === 'number') {
            setValue('sizeMax', value)
        }
    }

    const handleChangeSizeMin = (value: number | number[]) => {
        if (typeof value === 'number') {
            setValue('sizeMin', value)
        }
    }

    const handleChangePets = (e: ChangeEvent<HTMLInputElement>) => {
        // deselect dogs and cats when others selected
        const currentValues = watch('excludingPets')
        const value = e.target.value as Pet
        if (value === Pet.Other) {
            setValue('excludingPets', [Pet.Other])
        } else {
            const currentValuesWithoutOthers = currentValues?.filter((val) => val !== Pet.Other) || []
            setValue('excludingPets', [...currentValuesWithoutOthers, value] || [value])
        }
    }
    const handleChangeBodyType = (e: ChangeEvent<HTMLInputElement>) => {
        const currentValues = watch('excludingBodyType')
        if (currentValues && currentValues?.length === 2) {
            if (e.target.checked) {
                setValue('excludingBodyType', [...currentValues.slice(1), e.target.value as BodyType])
            }
        }
    }
    const handleNext = async () => {
        const values = getValues()
        const answeredValues = Object.keys(values).filter((key) => values[key as keyof NoGos_MeForm] !== undefined)
        if (!warningDissmissed && answeredValues.length < activeQuestionsIds.length) {
            setShowWarning(true)
            return null
        }
        dispatch(setError({ error: false, message: '' }))
        const updateObject = {
            ...romanticPreferences,
            ...values,
            noGosSeen: true,
        }
        Object.keys(updateObject).forEach((key) => {
            if (updateObject[key] === undefined) {
                updateObject[key] = null
            }
        })
        const heightObject = {
            min: values.sizeMin,
            max: values.sizeMax,
        }
        updateObject.prefferedHeight = heightObject
        delete updateObject.sizeMin
        delete updateObject.sizeMax
        updateRomanticPreferences(updateObject)
        dispatch(markStepCompleted(6))
        dispatch(nextStep())
    }

    useEffect(() => {
        const updateAnswers = (values: IPreferences) => {
            if (!values) return
            const { excludingBodyType, excludingSmokingConsumption, excludingAlcoholConsumption, excludingPets, prefferedHeight } = values
            if (excludingBodyType) {
                setValue('excludingBodyType', excludingBodyType)
                setActiveQuestionsIds((prev) => [...new Set([...prev, 'excludingBodyType'])])
            }
            if (excludingSmokingConsumption) {
                setValue('excludingSmokingConsumption', excludingSmokingConsumption)
                setActiveQuestionsIds((prev) => [...new Set([...prev, 'excludingSmokingConsumption'])])
            }
            if (excludingAlcoholConsumption) {
                setValue('excludingAlcoholConsumption', excludingAlcoholConsumption)
                setActiveQuestionsIds((prev) => [...new Set([...prev, 'excludingAlcoholConsumption'])])
            }
            if (excludingPets) {
                setValue('excludingPets', excludingPets)
                setActiveQuestionsIds((prev) => [...new Set([...prev, 'excludingPets'])])
            }
            if (prefferedHeight) {
                if (prefferedHeight.min) {
                    setValue('sizeMin', prefferedHeight.min)
                    setActiveQuestionsIds((prev) => [...new Set([...prev, 'sizeMin'])])
                }
                if (prefferedHeight.max) {
                    setValue('sizeMax', prefferedHeight.max)
                    setActiveQuestionsIds((prev) => [...new Set([...prev, 'sizeMax'])])
                }
            }
            // deduplicate
            setActiveQuestionsIds((prev) => [...new Set(prev)])
        }
        try {
            updateAnswers(romanticPreferences)
        } catch (e) {
            console.log(e)
        }
    }, [chosenDateType, romanticPreferences, setValue])

    const onQuestionClick = (questionId: string) => {
        const isQuestionActive = activeQuestionsIds.find((id) => id === questionId)
        if (isQuestionActive) {
            setValue(questionId as keyof NoGos_MeForm, undefined)
            setActiveQuestionsIds((prev) => prev.filter((id) => id !== questionId))
        } else {
            if (activeQuestionsIds.includes('sizeMax') && questionId === 'sizeMin') {
                setActiveQuestionsIds((prev) => {
                    const idsWithoutMax = prev.filter((id) => id !== 'sizeMax')
                    const newList = [...new Set([...idsWithoutMax, questionId])]
                    if (newList.length > 2) {
                        setValue(activeQuestionsIds[0] as keyof NoGos_MeForm, undefined)
                        return newList.slice(1)
                    } else {
                        return newList
                    }
                })
                setValue('sizeMax', undefined)
            } else if (activeQuestionsIds.includes('sizeMin') && questionId === 'sizeMax') {
                setActiveQuestionsIds((prev) => {
                    const idsWithoutMin = prev.filter((id) => id !== 'sizeMin')
                    const newList = [...new Set([...idsWithoutMin, questionId])]
                    if (newList.length > 2) {
                        setValue(activeQuestionsIds[0] as keyof NoGos_MeForm, undefined)
                        return newList.slice(1)
                    } else {
                        return newList
                    }
                })
                setValue('sizeMin', 120)
            } else {
                setActiveQuestionsIds((prev) => {
                    const newList = [...new Set([...prev, questionId])]
                    if (newList.length > 2) {
                        const questionToDeactivate = activeQuestionsIds[0]
                        if (questionToDeactivate === 'sizeMax') {
                            setValue('sizeMax', undefined)
                        } else if (questionToDeactivate === 'sizeMin') {
                            setValue('sizeMin', undefined)
                        } else {
                            setValue(activeQuestionsIds[0] as keyof NoGos_MeForm, undefined)
                        }
                        return newList.slice(1)
                    } else {
                        return newList
                    }
                })
                if (questionId === 'noKids') {
                    setValue('noKids', true)
                }
            }
        }
    }

    return (
        <StepContent
            title={<StepTitle title="Hier kannst du maximal 2 Eigenschaften benennen, die dein Date auf keinen Fall haben darf:" />}
            content={
                <div className={classes.root}>
                    <form className={clsx(classes.root, loading && classes.loading)}>
                        <NoGoQuestion
                            onSelect={onQuestionClick}
                            active={activeQuestionsIds.includes('excludingBodyType')}
                            question={noGoQuestions[0]}
                        >
                            <>
                                <CheckboxesInput
                                    className={classes.input}
                                    options={noGoQuestions[0].options}
                                    disabled={loading || !activeQuestionsIds.includes('excludingBodyType')}
                                    {...register('excludingBodyType')}
                                    onChange={handleChangeBodyType}
                                />
                            </>
                        </NoGoQuestion>
                        <NoGoQuestion
                            onSelect={onQuestionClick}
                            active={activeQuestionsIds.includes('excludingSmokingConsumption')}
                            question={noGoQuestions[1]}
                        >
                            <RadioInput
                                className={classes.input}
                                options={noGoQuestions[1].options}
                                disabled={loading || !activeQuestionsIds.includes('excludingSmokingConsumption')}
                                {...register('excludingSmokingConsumption')}
                            />
                        </NoGoQuestion>
                        <NoGoQuestion onSelect={onQuestionClick} active={activeQuestionsIds.includes('sizeMax')} question={noGoQuestions[2]}>
                            <HeightInput
                                handleChange={handleChangeSizeMax}
                                value={sizeMax}
                                disabled={loading || !activeQuestionsIds.includes('sizeMax')}
                            />
                        </NoGoQuestion>
                        <NoGoQuestion onSelect={onQuestionClick} active={activeQuestionsIds.includes('sizeMin')} question={noGoQuestions[3]}>
                            <HeightInput
                                handleChange={handleChangeSizeMin}
                                value={sizeMin}
                                disabled={loading || !activeQuestionsIds.includes('sizeMin')}
                            />
                        </NoGoQuestion>
                        <NoGoQuestion
                            onSelect={onQuestionClick}
                            active={activeQuestionsIds.includes('excludingAlcoholConsumption')}
                            question={noGoQuestions[4]}
                        >
                            <RadioInput
                                className={classes.input}
                                options={noGoQuestions[4].options}
                                disabled={loading || !activeQuestionsIds.includes('excludingAlcoholConsumption')}
                                {...register('excludingAlcoholConsumption')}
                            />
                        </NoGoQuestion>
                        <NoGoQuestion onSelect={onQuestionClick} active={activeQuestionsIds.includes('noKids')} question={noGoQuestions[5]}>
                            <input disabled={loading || !activeQuestionsIds.includes('noKids')} type="checkbox" {...register('noKids')} />
                        </NoGoQuestion>
                        <NoGoQuestion onSelect={onQuestionClick} active={activeQuestionsIds.includes('excludingPets')} question={noGoQuestions[6]}>
                            <CheckboxesInput
                                className={classes.input}
                                options={noGoQuestions[6].options}
                                disabled={loading || !activeQuestionsIds.includes('excludingPets')}
                                {...register('excludingPets')}
                                onChange={handleChangePets}
                            />
                        </NoGoQuestion>
                    </form>
                    {showWarning && !warningDissmissed && (
                        <Alert
                            className={classes.alert}
                            severity="warning"
                            action={
                                <Button color="inherit" size="small" onClick={handleWarningDismiss}>
                                    OK
                                </Button>
                            }
                        >
                            Du hast einige der Fragen als aktiv markiert, aber nicht beantwortet. Bist du sicher, dass du fortfahren möchtest?
                        </Alert>
                    )}
                </div>
            }
            buttons={
                <CreatorButtons
                    nextStepActive={true}
                    previousStepActive={true}
                    isFirst={false}
                    isLast={false}
                    skipActive={true}
                    handleNext={handleNext}
                />
            }
        />
    )
}
