import { styled } from '@mui/material/styles'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'

export const StepConnectorNamuho = styled(StepConnector)(({ theme }) => ({
    height: '100%',
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 32px)',
        right: 'calc(50% + 32px)',
    },
    [`& .${stepConnectorClasses.lineVertical}`]: {
        height: '100%',
        borderLeftWidth: 2,
        borderTopWidth: 0,
    },

    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.common.black,
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.common.black,
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.common.black,
    },
}))
