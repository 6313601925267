import { Divider } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import clsx from 'clsx'

export interface DottedDividerProps {
    className?: string
}
const useStyles = makeStyles()((theme) => ({
    root: {
        width: '100%',
        borderBottom: `1px dotted ${theme.palette.primary.main}`,
    },
}))

export const DottedDivider = (props: DottedDividerProps) => {
    const { className } = props
    const { classes } = useStyles()
    return <Divider className={clsx(classes.root, className)} />
}
