import { Button, Paper, Typography } from '@mui/material'
import { CalendarMonth as CalendarIcon, ChatOutlined as ChatIcon, ThumbsUpDown } from '@mui/icons-material/'
import { makeStyles } from 'tss-react/mui'
import { StatusBar } from './StatusBar'
import { getDisplayedGender } from './helpers'
import { ConsumableMatch } from '@/hooks/user/useUserMatches'
import { IMatchTimeObject, IMatchTimeStatus } from '@namuho/types'
import { useAuth } from '@/contexts'
import { formatDate } from '@namuho/helpers'

const useStyles = makeStyles()((theme) => ({
    root: {
        padding: theme.spacing(2),
        cursor: 'pointer',
        border: '1px solid transparent',
        backgroundColor: theme.palette.common.white,
        marginBottom: theme.spacing(1),
        '&: hover': {
            borderColor: theme.palette.primary.main,
        },
    },
    active: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
    },
    chatLine: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
    iconRow: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        fontSize: '0.6rem',
        gap: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
}))

export interface NavDateInfoProps {
    active: boolean
    meeting: ConsumableMatch
    onSelect: (id: string) => void
}

export const NavDateInfo = (props: NavDateInfoProps) => {
    const {
        meeting: { name, ratings, id, gender, timeSlot, timeObject },
        active,
        onSelect,
    } = props
    const { classes, cx } = useStyles()
    const { user } = useAuth()

    const handleClick = () => {
        onSelect(id)
    }
    const getDisplayedStatus = (timeObject: IMatchTimeObject) => {
        switch (timeObject.status) {
            case IMatchTimeStatus.Current:
                return (
                    <div className={classes.chatLine}>
                        <Button variant="outlined" color="go">
                            <ChatIcon />
                            Jetzt chatten
                        </Button>
                        <Typography variant="caption">Chat ist offen bis {formatDate(timeObject.dateChatCloses)}</Typography>
                    </div>
                )
            case IMatchTimeStatus.Future:
                return <Typography variant="h2">{`Chatten am ${formatDate(timeObject.dateChatOpens)}`}</Typography>
            case IMatchTimeStatus.PastOpen:
                return (
                    <>
                        <Button variant="outlined" color="go">
                            <ChatIcon />
                            Jetzt weiter chatten
                        </Button>
                    </>
                )
            case IMatchTimeStatus.PastWaiting:
                if (ratings?.[user?.id || ''] === undefined) {
                    return (
                        <>
                            <>
                                <Button variant="outlined" color="go">
                                    <ThumbsUpDown />
                                    Deine Erfahrung bewerten
                                </Button>
                            </>
                        </>
                    )
                } else {
                    return (
                        <Button variant="outlined" color="go">
                            Bewertung abgegeben
                        </Button>
                    )
                }
            case IMatchTimeStatus.PastClosed:
                return (
                    <>
                        <Button variant="outlined" color="go">
                            Chat geschlossen
                        </Button>
                    </>
                )
        }
    }
    return (
        <Paper className={cx(classes.root, active && classes.active)} onClick={handleClick}>
            <Typography variant="h2">
                {name} {getDisplayedGender(gender)}
            </Typography>
            <div className={classes.iconRow}>
                <CalendarIcon />
                <Typography variant="subtitle2">{formatDate(timeSlot?.date)}</Typography>
            </div>
            <div className={classes.iconRow}>{getDisplayedStatus(timeObject)}</div>
            <StatusBar date={props.meeting} size="small" />
        </Paper>
    )
}
