import { createTheme } from '@mui/material/styles'

const colors = {
    black: '#000000',
    white: '#FFFFFF',
    yellow: '#F9A826',
    gray: '#F4F4F4',
    green: '#367a6a',
    beige: '#F9F7F0',
    pink: '#F4B9B2',
    red: '#C8445D',
}

const theme = createTheme({
    typography: {
        fontSize: 20,
        fontFamily: 'Avenir Next',
        h1: {
            fontSize: '2rem',
            fontWeight: 700,
        },
        h2: {
            fontSize: '1.1rem',
            fontWeight: 700,
        },
        link: {
            extends: 'body1',
            cursor: 'pointer',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    },
    palette: {
        mode: 'light',
        primary: {
            main: colors.red,
            contrastText: colors.white,
        },
        secondary: {
            main: colors.pink,
            contrastText: colors.black,
        },
        romantic: {
            main: colors.gray,
            contrastText: colors.black,
        },
        go: {
            main: colors.green,
            contrastText: colors.white,
        },
        text: {
            primary: colors.black,
            secondary: colors.white,
            disabled: colors.gray,
        },
        background: {
            default: colors.gray,
            paper: colors.red,
            romantic: colors.beige,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                outlined: {
                    background: colors.white,
                    '&:hover': {
                        background: colors.white,
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    fontSize: '1rem',
                    color: colors.white,
                    '&:hover label': {
                        color: colors.red,
                    },
                    '&:hover input': {
                        color: colors.red,
                    },
                    '&:focus input': {
                        color: colors.red,
                    },
                    '&:active input': {
                        color: colors.red,
                    },
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    backgroundColor: colors.red,
                    color: colors.white,
                    borderRadius: 0,
                    '&:hover': {
                        color: colors.red,
                    },
                    '&:hover input': {
                        color: colors.red,
                    },
                    '&:focus input': {
                        color: colors.red,
                    },
                    '&:active input': {
                        color: colors.red,
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    borderRadius: 5,
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    '& .MuiSvgIcon-root': {
                        color: colors.red,
                        fontSize: 28,
                    },
                    '&, &.Mui-checked': {
                        color: colors.red,
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    '& .MuiSvgIcon-root': {
                        fill: colors.red,
                    },
                    '&.Mui-disabled .MuiSvgIcon-root': {
                        opacity: 0.6,
                    },
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled .MuiFormControlLabel-label': {
                        color: 'green',
                    },
                },
            },
        },

        MuiAccordion: {
            styleOverrides: {
                root: {
                    backgroundColor: colors.white,
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                },
            },
        },
    },
})

export default theme
