import { Footer } from '@/components/nav/Footer'
import { Creator } from '@/components/stepper/Creator'
import { RootState } from '@/store'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { makeStyles } from 'tss-react/mui'
import namuhoWalking from '@/assets/namuho-walking.png'
import namuhoCoffeePeople from '@/assets/namuho-coffee-people.png'
import namuhoRestaurant from '@/assets/namuho-restaurant.png'
import namuhoBench from '@/assets/namuho-bench.png'
import { MeetingType } from '@namuho/types'
import clsx from 'clsx'
import { StepperProfileInfo } from '@/components/common/StepperProfileInfo'
import { CatchError } from '@/components/CatchError'

const useStyles = makeStyles()((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        gap: theme.spacing(2),
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        height: 'auto',
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(4),
        backgroundColor: theme.palette.background.romantic,
    },
    title: {
        zIndex: 2,
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            marginLeft: theme.spacing(2),
        },
    },
    container: {
        width: '90vw',
        padding: theme.spacing(2),
        maxWidth: '100%',
        zIndex: 2,
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(2),
            padding: theme.spacing(1),
            width: '96vw',
        },
    },
    bgImage1: {
        position: 'absolute',
        bottom: 150,
        width: 300,
        left: 10,
        opacity: 0,
        transition: 'all 500ms ease-in-out',
    },
    bgImage2: {
        position: 'absolute',
        width: 200,
        top: 20,
        right: 10,
        opacity: 0,
        transition: 'all 500ms ease-in-out',
    },
    imageVisible: {
        opacity: '1 !important',
    },
}))

interface StartProps {
    className?: string
}

export function Start(props: StartProps) {
    const { classes } = useStyles()
    const chosenDateType = useSelector((state: RootState) => state.creator.chosenDateType)
    const activeStep = useSelector((state: RootState) => state.creator.activeStepIndex)
    const { t } = useTranslation()

    return (
        <>
            <img src={namuhoBench} alt="Couple on a bench" className={clsx(classes.bgImage1, activeStep < 5 ? classes.imageVisible : '')} />
            <img src={namuhoWalking} alt="Couple walking with dogs" className={clsx(classes.bgImage1, activeStep >= 5 ? classes.imageVisible : '')} />
            <img
                src={namuhoCoffeePeople}
                alt="People drinking coffee"
                className={clsx(classes.bgImage2, chosenDateType === MeetingType.Friendly ? classes.imageVisible : '')}
            />
            <img
                src={namuhoRestaurant}
                alt="Couple in a restaruant"
                className={clsx(classes.bgImage2, chosenDateType === MeetingType.Romantic ? classes.imageVisible : '')}
            />
            <CatchError section="start">
                <Box component="main" className={classes.root} {...props}>
                    <StepperProfileInfo />
                    {chosenDateType && activeStep > 3 && (
                        <Typography className={classes.title} variant="h2">
                            Vorbereitung der ersten Verabredung: {t(`dateTypes.${chosenDateType}`)}
                        </Typography>
                    )}
                    <Box className={classes.container}>
                        <Creator />
                    </Box>
                </Box>
            </CatchError>
            <Footer />
        </>
    )
}
