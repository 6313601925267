import { AlcoholConsumption, BodyType, Pet, SmokingConsumption } from '@namuho/types'
import { RadioInputOption } from '../common/RadioInput'

export interface NoGos_Questions {
    title: string
    instruction: string
    id: string
    options: RadioInputOption[]
}

export const noGoQuestions: NoGos_Questions[] = [
    {
        title: 'Figur',
        instruction: 'Meine Date darf nicht (max 2 Optionen):',
        id: 'excludingBodyType',
        options: [
            {
                value: BodyType.Slim,
                label: 'schlank',
            },
            {
                value: BodyType.Normal,
                label: 'normalgewichtig sein',
            },
            {
                value: BodyType.Athletic,
                label: 'sportlich sein',
            },

            {
                value: BodyType.AboveAverage,
                label: 'ein paar Kilos zu viel haben',
            },
            {
                value: BodyType.Obese,
                label: 'herzhaft sein',
            },
        ],
    },
    {
        title: 'Raucher',
        instruction: 'Meine Date darf',
        id: 'excludingSmokingConsumption',
        options: [
            { value: SmokingConsumption.Sometimes, label: 'nur gelegentlich rauchen' },
            {
                value: SmokingConsumption.Never,
                label: 'gar nicht rauchen',
            },
        ],
    },
    {
        title: 'Größe',
        instruction: 'Meine Date darf NICHT größer sein als:',
        id: 'sizeMax',
        options: [],
    },
    {
        title: 'Größe',
        instruction: 'Meine Date darf NICHT kleiner sein als:',
        id: 'sizeMin',
        options: [],
    },
    {
        title: 'Alkoholkonsum',
        instruction: 'Meine Date darf',
        id: 'excludingAlcoholConsumption',
        options: [
            {
                value: AlcoholConsumption.Sometimes,
                label: 'nur gelegentlich Alkohol trinken',
            },
            {
                value: AlcoholConsumption.Never,
                label: 'gar nicht Alkohol trinken',
            },
        ],
    },
    {
        title: 'Kinder',
        instruction: 'Meine Date darf KEINE Kinder haben',
        id: 'noKids', // only option is my date can not have kids
        options: [],
    },
    {
        title: 'Haustiere',
        id: 'excludingPets',
        instruction: 'Meine Date darf:',
        options: [
            {
                value: Pet.Dog,
                label: 'keinen Hund haben',
            },
            {
                value: Pet.Cat,
                label: 'keine Katze haben',
            },

            {
                value: Pet.Other,
                label: 'gar kein Haustier haben',
            },
        ],
    },
]
