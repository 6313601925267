import { ConsumableMatch, MatchRejectObject, useUserMatches } from '@/hooks/user/useUserMatches'
import { ThumbDown, ThumbUp } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import { IMatch, MeetingType } from '@namuho/types'
import { useState } from 'react'
import { makeStyles } from 'tss-react/mui'

export interface RejectModalProps {
    onClose: () => void
    open: boolean
    match: ConsumableMatch
}
const useStyles = makeStyles()((theme) => ({
    root: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.common.white,
    },
    buttons: {
        marginTop: theme.spacing(2),
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        gap: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
    textArea: {
        padding: theme.spacing(0.5),
        width: '100%',
        border: `1px solid ${theme.palette.secondary.main}`,
    },
    actions: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        gap: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
}))
export const RejectModal = (props: RejectModalProps) => {
    const { open, onClose, match } = props
    const { classes } = useStyles()
    const [comment, setComment] = useState<string>('')
    const [buttonsSeen, setButtonsSeen] = useState(false)
    const { rejectMatch } = useUserMatches()

    const handleSubmit = async () => {
        if (!match) return
        if (!match.timeSlot) return
        const rejectObject: MatchRejectObject = {
            matchId: match.id,
            timeSlot: match.timeSlot,
            comment: comment,
        }
        await rejectMatch(rejectObject)
        onClose()
    }
    const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setComment(e.target.value)
        setButtonsSeen(true)
    }

    return (
        <Dialog fullWidth maxWidth="md" classes={{ paper: classes.root }} open={open}>
            <DialogTitle>
                Dein {match?.meetingType === MeetingType.Romantic ? 'Date' : 'Treffen'} mit {match?.name} absagen?
            </DialogTitle>
            <DialogContent>
                <Typography variant="h2">
                    Geänderte Pläne? Bis zu 24 Stunden vor dem Treffen können Sie es absagen, wir werden ein neues Spiel für Sie vorbereiten.
                </Typography>
                <div className={classes.buttons}>
                    <textarea
                        className={classes.textArea}
                        value={comment}
                        placeholder="Schreiben Sie uns, warum Sie das Treffen absagen möchten."
                        onChange={handleCommentChange}
                    ></textarea>
                </div>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button onClick={onClose} variant="outlined" color="primary">
                    Abbrechen
                </Button>
                <Button disabled={!buttonsSeen} onClick={handleSubmit} variant="contained" color="primary">
                    Absagen
                </Button>
            </DialogActions>
        </Dialog>
    )
}
