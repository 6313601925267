import { RootState } from '@/store'
import { Alert, Box, Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from 'tss-react/mui'
import { previousStepGo } from '@/slices/creatorGo'
import { useTranslation } from 'react-i18next'
import { MeetingType } from '@namuho/types'
import { useSearchParams } from 'react-router-dom'

export interface CreatorButtonsGoProps {
    nextStepActive: boolean
    previousStepActive: boolean
    isFirst: boolean
    isLast: boolean
    dateType: MeetingType
    handleNext: () => void
}

type ClassKeys = void | 'buttons' | 'nextStepButton' | 'belowButtons' | 'skipButton' | 'finish'

const useStyles = makeStyles<ClassKeys>()((theme) => ({
    buttons: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(2),
        justifyContent: 'space-between',
    },
    nextStepButton: {
        marginLeft: 'auto',
    },
    belowButtons: {
        marginTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'flex-end',
    },
    skipButton: {
        color: theme.palette.text.primary,
        fontSize: '0.8rem',
    },
    finish: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

export const CreatorButtonsGo = (props: CreatorButtonsGoProps) => {
    const { dateType, nextStepActive, previousStepActive, isFirst, isLast, handleNext } = props
    const { classes } = useStyles()
    const { t } = useTranslation()
    const [_, setSearchParams] = useSearchParams()

    const handleClickNext = () => {
        setSearchParams({})
        handleNext()
    }

    const handleClickPrevious = () => {
        setSearchParams({})
        dispatch(previousStepGo(dateType))
    }

    const activeStepIndex = useSelector((state: RootState) => state.creatorGo[dateType].activeStepIndex)
    const creatorError = useSelector((state: RootState) => state.creatorGo[dateType].error)
    const dispatch = useDispatch()

    return (
        <>
            {creatorError.error && <Alert severity="error">{creatorError.message}</Alert>}
            <Box className={classes.buttons}>
                {activeStepIndex !== 0 && !isFirst && (
                    <Button
                        variant="contained"
                        tabIndex={2}
                        color="inherit"
                        disabled={!previousStepActive}
                        onClick={handleClickPrevious}
                        sx={{ mr: 1 }}
                    >
                        {t('steps.previousStep')}
                    </Button>
                )}
                <Button className={classes.nextStepButton} onClick={handleClickNext} disabled={!nextStepActive} variant="contained">
                    {isLast ? t('steps.finish') : t('steps.nextStep')}
                </Button>
            </Box>
        </>
    )
}
