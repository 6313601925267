import { MeetingType } from '@namuho/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum CreatorGoSteps {
    BASIC_QUESTIONS = 'BASIC_QUESTIONS',
    NOGOS_ME = 'NOGOS_ME',
    NOGOS_YOU = 'NOGOS_YOU',
    RATED_QUESTIONS = 'RATED_QUESTIONS',
    SECRETS = 'SECRETS',
    PRODUCTS = 'PRODUCTS',
    DATES = 'DATES',
}

export interface Step {
    id: CreatorGoSteps
    index: number
    completed: boolean
}

export interface CreatorError {
    error: boolean
    message: string
}

export type Steps = Step[]

export interface CreatorState {
    steps: Steps
    activeStepIndex: number
    error: CreatorError
}

export interface CreatorGoState {
    [MeetingType.Friendly]: CreatorState
    [MeetingType.Romantic]: CreatorState
    [MeetingType.Group]: CreatorState
}

const initialState: CreatorGoState = {
    [MeetingType.Friendly]: {
        activeStepIndex: 0,
        error: {
            error: false,
            message: '',
        },
        steps: [
            {
                id: CreatorGoSteps.BASIC_QUESTIONS,
                index: 0,
                completed: false,
            },
            {
                id: CreatorGoSteps.RATED_QUESTIONS,
                index: 1,
                completed: false,
            },
            {
                id: CreatorGoSteps.SECRETS,
                index: 2,
                completed: false,
            },
            {
                id: CreatorGoSteps.PRODUCTS,
                index: 3,
                completed: false,
            },
            {
                id: CreatorGoSteps.DATES,
                index: 4,
                completed: false,
            },
        ],
    },
    [MeetingType.Group]: {
        activeStepIndex: 0,
        error: {
            error: false,
            message: '',
        },
        steps: [
            {
                id: CreatorGoSteps.BASIC_QUESTIONS,
                index: 0,
                completed: false,
            },
            {
                id: CreatorGoSteps.RATED_QUESTIONS,
                index: 1,
                completed: false,
            },
            {
                id: CreatorGoSteps.PRODUCTS,
                index: 2,
                completed: false,
            },
            {
                id: CreatorGoSteps.DATES,
                index: 3,
                completed: false,
            },
        ],
    },
    [MeetingType.Romantic]: {
        activeStepIndex: 0,
        error: {
            error: false,
            message: '',
        },
        steps: [
            {
                id: CreatorGoSteps.BASIC_QUESTIONS,
                index: 0,
                completed: false,
            },
            {
                id: CreatorGoSteps.NOGOS_ME,
                index: 1,
                completed: false,
            },
            {
                id: CreatorGoSteps.NOGOS_YOU,
                index: 2,
                completed: false,
            },
            {
                id: CreatorGoSteps.RATED_QUESTIONS,
                index: 3,
                completed: false,
            },
            {
                id: CreatorGoSteps.SECRETS,
                index: 4,
                completed: false,
            },
            {
                id: CreatorGoSteps.PRODUCTS,
                index: 5,
                completed: false,
            },
            {
                id: CreatorGoSteps.DATES,
                index: 6,
                completed: false,
            },
        ],
    },
}

export const creatorGoSlice = createSlice({
    name: 'creatorGo',
    initialState,
    reducers: {
        nextStep: (state, action: PayloadAction<MeetingType>) => {
            if (state[action.payload].error.error) {
                return state
            }
            if (state[action.payload].activeStepIndex === state[action.payload].steps.length - 1) {
                return state
            }

            window.goatcounter.count({
                path: 'goStepper/nextStep',
                title: `navigate to next step ${state[action.payload].activeStepIndex + 1} path ${action.payload}`,
                event: true,
            })
            window.scrollTo(0, 0)
            state[action.payload].activeStepIndex = state[action.payload].activeStepIndex + 1
        },
        previousStepGo: (state, action: PayloadAction<MeetingType>) => {
            if (state[action.payload].error.error) {
                return state
            }
            if (state[action.payload].activeStepIndex === 0) {
                state[action.payload].activeStepIndex = 0
            }
            window.goatcounter.count({
                path: 'goStepper/previousStep',
                title: `navigate to previous step ${state[action.payload].activeStepIndex - 1} path ${action.payload}`,
                event: true,
            })
            window.scrollTo(0, 0)
            state[action.payload].activeStepIndex = state[action.payload].activeStepIndex - 1
        },
        resetError: (state, action: PayloadAction<MeetingType>) => {
            state[action.payload].error = {
                error: false,
                message: '',
            }
        },
        goToStepById: (state, action: PayloadAction<{ dateType: MeetingType; step: CreatorGoSteps }>) => {
            const steps = state[action.payload.dateType].steps
            const affectedStep = steps.find((step) => step.id === action.payload.step)
            if (affectedStep) {
                window.scrollTo(0, 0)
                state[action.payload.dateType].activeStepIndex = affectedStep.index
            }
        },
        goToPaymentStep: (state, action: PayloadAction<MeetingType>) => {
            const paymentStep = state[action.payload].steps.find((step) => step.id === CreatorGoSteps.PRODUCTS)
            if (paymentStep) {
                window.scrollTo(0, 0)
                state[action.payload].activeStepIndex = paymentStep.index
            }
        },
        goToStepNumber: (state, action: PayloadAction<{ dateType: MeetingType; number: number }>) => {
            window.scrollTo(0, 0)
            window.goatcounter.count({
                path: 'goStepper/goToStepNumber',
                title: `navigate to step by number ${action.payload.number} path ${action.payload.dateType}`,
                event: true,
            })
            state[action.payload.dateType].activeStepIndex = action.payload.number
        },
        markStepCompleted: (state, action: PayloadAction<{ dateType: MeetingType; step: CreatorGoSteps }>) => {
            const steps = state[action.payload.dateType].steps
            const affectedStep = steps.find((step) => step.id === action.payload.step)
            if (affectedStep) {
                affectedStep.completed = true
            }
        },
        setError: (state, action: PayloadAction<{ dateType: MeetingType; error: CreatorError }>) => {
            state[action.payload.dateType].error = action.payload.error
        },
    },
})

// Action creators are generated for each case reducer function
export const { nextStep, previousStepGo, goToStepNumber, goToStepById, goToPaymentStep, markStepCompleted, resetError, setError } =
    creatorGoSlice.actions

export default creatorGoSlice.reducer
