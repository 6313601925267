import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(() => ({
    paths: {
        fill: 'currentcolor',
    },
}))

export interface LogoProps {
    className?: string
}

export function Logo(props: LogoProps) {
    const { classes } = useStyles()
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 618.02 188.82">
            <g className={classes.paths}>
                <g>
                    <path d="M182.26,184.7,177,179.37c16.8-16.51,43.51-28.06,79.41-34.33,35-6.13,84.11-4,86.18-3.87,69.53,3.2,89.53,5.12,105.6,6.66,10.89,1,19.49,1.87,40.86,2.79,78.57,3.39,101-3.07,115.89-7.35l.09,0a8.39,8.39,0,0,1,10.51,6.23l2.46,11c-2.34.52-4.74,1.21-7.52,2-16.12,4.64-40.46,11.65-122.3,8.11-21.88-.94-30.72-1.79-41.9-2.86-15.85-1.52-35.58-3.42-104.61-6.59-16.79-.77-55.49-1-81.82,3.59-25.84,4.51-46,11.77-60,21.63A13.66,13.66,0,0,1,182.26,184.7Z" />
                    <path d="M76.74,91.15A98.73,98.73,0,0,1,74.8,74V7.61s21.69,0,21.69,10.52V125C72,125,67,113.33,67,113.33L19.91,38.53a107.19,107.19,0,0,1,1.78,17.16V125S0,125,0,114.46V7.61c13,0,23.64,0,29.3,9.23Z" />
                    <path d="M164.65,64.76c0-5.83-3.24-11-15.71-11-13.6,0-25.9,4.7-25.9,18.3-4.7-6.32-6.15-11.34-6.15-15.71,0-14.08,18.94-18.29,32.21-18.29,23.8,0,36.43,10,36.43,27.68V125a26.94,26.94,0,0,0-12.47-2.59c-8.09,0-16.19,2.91-25.41,2.91-16.68,0-33-6.15-33-25.42C114.62,69.62,158,77.55,164.65,64.76Zm.48,16.51c-3.72,8.58-29.63,3.89-29.63,18.3,0,7.28,5.35,11,14.58,11a40.23,40.23,0,0,0,15-2.92Z" />
                    <path d="M256,67.19c0-8.58-4-13.44-15.54-13.44A56.82,56.82,0,0,0,225,56V124.5s-21,0-21-10.52V38.05a33.77,33.77,0,0,0,14.57,3.4c7.93,0,15.38-3.4,24.12-3.4,9.23,0,18.94,1.78,25.58,7,0,0,7.45-7,27.68-7,17.81,0,33,9.06,33,28V124.5s-21.05-.32-21.05-10.52V68.48c0-10.52-5.66-15.05-13.44-15.05-8.58,0-17.16,3.72-17.48,11.33V124.5S256,124.18,256,114Z" />
                    <path d="M369.44,96.17c0,8.58,4.05,13.44,15.54,13.44a56.73,56.73,0,0,0,15.38-2.27V38.86s21.05,0,21.05,10.52v75.93a33.77,33.77,0,0,0-14.57-3.4c-7.93,0-15.38,3.4-24.12,3.4-16.68,0-34.32-5.67-34.32-26.71V38.86s21,.32,21,10.52Z" />
                    <path d="M461.88,124.5s-21,0-21-10.52V0c1.13,0,21-.48,21,12.15v22.5a54.42,54.42,0,0,1-1.29,9.71s7.77-6.31,20.88-6.31c17.33,0,32.38,9.06,32.38,28V124.5s-21-.32-21-10.52V68.48c0-10.52-5.67-15.05-13.44-15.05-8.74,0-17.49,5.5-17.49,13.44Z" />
                    <path d="M609.69,96.82c0,17.81-12.46,28.49-39,28.49s-39-10.68-39-28.49V66.54c0-17.81,12.46-28.49,39-28.49s39,10.68,39,28.49ZM588.65,69c0-9.88-3.08-15.54-18-15.54s-18,5.66-18,15.54V94.39c0,9.87,3.07,15.54,18,15.54s18-5.67,18-15.54Z" />
                </g>
            </g>
        </svg>
    )
}
