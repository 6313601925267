import { StepContent } from '../stepper/StepContent'
import { useDispatch } from 'react-redux'
import { CreatorButtonsGo } from './CreatorButtonsGo'
import { CreatorGoSteps, markStepCompleted, nextStep } from '@/slices/creatorGo'
import { CreatorGoProps } from './CreatorGo'
import { ProductsBuy } from '../common/ProductsBuy'
import { makeStyles } from 'tss-react/mui'
import { Typography } from '@mui/material'

const useStyles = makeStyles()((theme) => ({
    promo: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}))
export const ProductsGo = (props: CreatorGoProps) => {
    const { dateType } = props
    const dispatch = useDispatch()
    const { classes } = useStyles()

    const handleBought = () => {
        dispatch(markStepCompleted({ dateType: dateType, step: CreatorGoSteps.PRODUCTS }))
    }

    const handleNext = () => {
        dispatch(nextStep(dateType))
    }

    return (
        <StepContent
            variant="transparent"
            content={<ProductsBuy dateType={dateType} onBought={handleBought} />}
            buttons={
                <CreatorButtonsGo
                    dateType={dateType}
                    nextStepActive={true}
                    previousStepActive={true}
                    isFirst={false}
                    isLast={false}
                    handleNext={handleNext}
                />
            }
        />
    )
}
