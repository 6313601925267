import { IMetadata } from './metadata'
import { ISlot } from './slots'

export * from './slots'
export * from './matchSecrets'
export * from './match'
export * from './waitingForMatch'
export * from './product'
export * from './authContext'
export * from './transactions'
export * from './notification'
export * from './discountCodes'
export * from './product_v2'

export enum City {
    Aachen = 'Aachen',
    Cologne = 'Köln',
}

export enum MeetingType {
    Friendly = 'Freundschaftlich',
    Romantic = 'Romantisch',
    Group = 'Gruppentreffen',
}

export enum Gender {
    Male = 'männlich',
    Female = 'weiblich',
    Other = 'andere',
}

export enum Country {
    Germany = 'Deutschland',
    Belgium = 'Belgien',
    Netherlands = 'Niederlande',
}

export interface IPreferredAge {
    min: number
    max: number
}
// in No Gos user can choose only one of those
export interface IPrefferedHeight {
    min: number | undefined
    max: number | undefined
}

export enum SmokingConsumption {
    Never = 'Nie',
    Sometimes = 'Gelegentlich',
    Regularly = 'Regelmäßig',
}

export enum BodyType {
    Slim = 'Schlank',
    Normal = 'Normal',
    Athletic = 'Sportlich',
    AboveAverage = 'ein paar Kilos zu viel',
    Obese = 'Herzhaft',
}

export enum AlcoholConsumption {
    Never = 'Nie',
    Sometimes = 'Gelegentlich',
    Regularly = 'Regelmäßig',
}

export enum Pet {
    Dog = 'Hund',
    Cat = 'Katze',
    Other = 'Andere',
}

export interface IPersonalDetails {
    smokingConsumption?: SmokingConsumption
    bodyType?: BodyType
    height?: number
    alcoholConsumption?: AlcoholConsumption
    kids?: boolean
    pets?: Pet[]
}

export interface IPreferences {
    prefferedAge?: IPreferredAge
    prefferedGender?: Gender[]
    // we need to be able to keep those object keys while no storing values to override choices in no goes
    // those are no goes, so more those conditions are deal breakers
    excludingSmokingConsumption?: SmokingConsumption | null
    excludingBodyType?: BodyType[] | null
    excludingAlcoholConsumption?: AlcoholConsumption | null
    excludingPets?: Pet[] | null
    prefferedHeight?: IPrefferedHeight | null
    noKids?: boolean | null
    noGosSeen?: boolean // they can choose not to select anything, but it is good to know they had a chance
    weightedQuestions?: IWeightedQuestionAnswer[]
    secretQuestions?: ISecretsQuestionAnswer[]
    numberOfAvailableSlots?: number // number of dates bought
}

export type WeightedAnswer = 0 | 1 | 2 | 3 | 4 | 5

export interface IWeightedQuestionAnswer {
    questionId: string
    answer: WeightedAnswer // what is the answer from 1 to 5 (not true, to true), 0 is not answered
}

export interface ISecretsQuestionAnswer {
    questionId: string
    answer: string
}

export interface IUser extends IUserAttributes {
    id: string
}

export interface IUserDao extends IUserAttributes {
    PK: string
    SK: string
    SK2: string
    TIMESTAMP: number
    TYPE: 'USER'
}

export interface IUserAttributes {
    firstname: string
    lastname: string
    email: string
    /** stored as a ISO Timestamp */
    birthdate: string
    gender: Gender
    country: Country
    city?: City
    personalDetails?: IPersonalDetails
    [MeetingType.Romantic]?: IPreferences
    [MeetingType.Group]?: IPreferences
    [MeetingType.Friendly]?: IPreferences
    alreadyMatchedWith?: string[] // userIds
    totalNumberOfDatesBought?: number // to easily keep track of total
    totalNumberOfFriendlyDatesBought?: number
    totalNumberOfRomanticDatesBought?: number
    datesForStart?: number // number of dates for free for new account
    ratings?: number[]
    comments?: string[]
    /** Array of ISO Timestamps */
    timeSlots?: ISlot[] // when user is currently available
    _metadata: IMetadata
}

export enum QuestionGroup {
    Weighted = 'Weighted',
    Secrets = 'Secrets',
}

export interface IQuestion {
    id: string
    group: QuestionGroup
    label: string
    createdAt: string // TODO: convert to IMetadata
    meetingType: MeetingType
    weight?: number
}

export interface IMessage extends IMessageAttributes {
    id: string
    matchId: string
}

export interface IMessageDao extends IMessageAttributes {
    PK: string
    SK: string
    TYPE: 'MESSAGE'
}

export interface IMessageAttributes {
    content: string
    _metadata: IMetadata
}

export interface Key {
    PK: string
    SK: string
}
