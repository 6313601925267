import { Gender } from '@namuho/types'

export const getDisplayedGender = (gender: any) => {
    switch (gender) {
        case Gender.Female:
            return '(W)'
        case Gender.Male:
            return '(M)'
        case 'Female':
            return '(W)'
        case 'Male':
            return '(M)'
        case Gender.Other:
            return ''
    }
}
