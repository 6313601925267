import { useChat } from '@/hooks/chat/useChat'
import { ConsumableMatch } from '@/hooks/user/useUserMatches'
import { Button } from '@mui/material'
import { IMatchTimeStatus } from '@namuho/types'
import { useRef, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { RejectModal } from './RejectModal'

const useStyles = makeStyles()((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
        gap: theme.spacing(2),
    },
    textArea: {
        width: '100%',
        padding: theme.spacing(1),
    },
    buttons: {
        display: 'flex',
        gap: theme.spacing(1),
    },
}))

export interface EditWindowProps {
    match: ConsumableMatch | undefined
}

export const EditWindow = (props: EditWindowProps) => {
    const { match } = props
    const { classes } = useStyles()
    const [message, setMessage] = useState<string>('')
    const [closingModalOpen, setClosingModalOpen] = useState<boolean>(false)
    const { sendMessage } = useChat(match?.id)
    const handleSend = (e: React.FormEvent) => {
        e.preventDefault()
        sendMessage(message)
        setMessage('')
    }
    // form ref
    const myFormRef = useRef<HTMLFormElement | null>(null)

    const handleEnterPress = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' && e.shiftKey === false && myFormRef.current) {
            e.preventDefault()
            handleSend(e as React.FormEvent)
        }
    }

    const handleReject = () => {
        setClosingModalOpen(true)
    }

    const handleCloseModal = () => {
        setClosingModalOpen(false)
    }

    const isChatPossible = match?.timeObject.status === IMatchTimeStatus.Current || match?.timeObject.status === IMatchTimeStatus.PastOpen

    return (
        <>
            <form className={classes.root} onSubmit={handleSend} ref={myFormRef}>
                <textarea
                    placeholder="Hey! Ich freue mich, dich zu sehen. Wo sollen wir uns treffen?"
                    className={classes.textArea}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyDown={handleEnterPress}
                    disabled={!isChatPossible}
                ></textarea>
                <div className={classes.buttons}>
                    {match?.timeObject?.status === IMatchTimeStatus.Future && (
                        <Button onClick={handleReject} variant="outlined" color="primary">
                            Absagen
                        </Button>
                    )}
                    <Button type="submit" variant="contained" color="primary" disabled={!isChatPossible}>
                        Schicken
                    </Button>
                </div>
            </form>
            {match && <RejectModal match={match} open={closingModalOpen} onClose={handleCloseModal} />}
        </>
    )
}
