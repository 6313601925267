import { Container, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { clsx } from 'clsx'
import { getDisplayedGender } from './dates/helpers'
import { ConsumableMatch, useUserMatches } from '@/hooks/user/useUserMatches'
import { Swipe, ThumbDown, ThumbUp } from '@mui/icons-material'
import { useAuth } from '@/contexts'
import { IMatchTimeStatus } from '@namuho/types'
import { Link } from 'react-router-dom'
import { formatDate, formatDateDayOnly, formatDateHourOnly } from '@namuho/helpers'

const useStyles = makeStyles()((theme) => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    table: {
        marginTop: theme.spacing(2),
        backgroundColor: theme.palette.common.white,
        borderRadius: 20,
        marginBottom: theme.spacing(4),
    },
    swipeIcon: {
        display: 'none',
        [theme.breakpoints.down('md')]: {
            display: 'block',
            marginLeft: theme.spacing(4),
            marginBottom: theme.spacing(4),
        },
    },
}))

export interface OverviewProps {
    className?: string
}

export function Overview(props: OverviewProps) {
    const { classes } = useStyles()
    const { matches, isLoading } = useUserMatches()
    const { user } = useAuth()

    const getMatchStatusCell = (match: ConsumableMatch) => {
        if (match.timeObject.status === IMatchTimeStatus.Future) {
            return <span>Neu</span>
        }
        if (match.timeObject.status === IMatchTimeStatus.Current) {
            return (
                <Link to="/dates" className="action-link">
                    Jetzt chatten
                </Link>
            )
        }
        if (match.timeObject.status === IMatchTimeStatus.PastClosed) {
            return <span>Chat geschlossen</span>
        }
        if (match.timeObject.status === IMatchTimeStatus.PastOpen) {
            return (
                <Link to={`/dates?activeDateId=${encodeURIComponent(match.id)}`} className="action-link">
                    Weiter chatten
                </Link>
            )
        }
        if (match.timeObject.status === IMatchTimeStatus.PastWaiting) {
            if (match.ratings?.[user?.id || ''] === undefined) {
                return (
                    <Link to={`/dates?activeDateId=${encodeURIComponent(match.id)}`} className="action-link">
                        Bewerten
                    </Link>
                )
            } else {
                return <span>Wartet für {match.name} Bewertung</span>
            }
        }
        return ' - '
    }
    return (
        <Container maxWidth={false} className={clsx(classes.root, props.className)} id="overview">
            <Typography variant="h1">Übersicht</Typography>
            <TableContainer component={Box}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Datum</TableCell>
                            <TableCell>Uhrzeit</TableCell>
                            <TableCell>Mit wem</TableCell>
                            <TableCell>Art</TableCell>
                            <TableCell>Chat Start</TableCell>
                            <TableCell>Chat Ende</TableCell>
                            <TableCell>Deine Bewertung</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {matches.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={4}>
                                    <Typography variant="body1">Noch keine Matches</Typography>
                                </TableCell>
                            </TableRow>
                        )}
                        {matches.map((match: ConsumableMatch) => (
                            <TableRow key={match.id}>
                                <TableCell component="th" scope="row">
                                    {formatDateDayOnly(match.timeSlot?.date)}
                                </TableCell>
                                <TableCell>{formatDateHourOnly(match.timeSlot?.date)}</TableCell>
                                <TableCell>
                                    {match.name} {getDisplayedGender(match.gender)}
                                </TableCell>
                                <TableCell>{match.meetingType}</TableCell>
                                <TableCell>{match.timeObject.dateChatOpens ? formatDate(match.timeObject?.dateChatOpens) : ' - '}</TableCell>
                                <TableCell>{match.timeObject.dateChatCloses ? formatDate(match.timeObject?.dateChatCloses) : ' - '}</TableCell>
                                <TableCell>
                                    {match.ratings?.[user?.id || ''] === 0 ? (
                                        <ThumbDown />
                                    ) : match.ratings?.[user?.id || ''] === 1 ? (
                                        <ThumbUp />
                                    ) : (
                                        ' - '
                                    )}
                                </TableCell>
                                <TableCell>{getMatchStatusCell(match)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Swipe className={classes.swipeIcon} />
            </TableContainer>
        </Container>
    )
}
