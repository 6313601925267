import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from 'tss-react/mui'
import { useForm } from 'react-hook-form'
import { Date, markStepsAccessible, markStepCompleted, nextStep, setChosenDateType, markStepCompletedByName, CreatorSteps } from '@/slices/creator'
import { RadioInput } from '../common/RadioInput'
import { StepContent } from './StepContent'
import { RootState } from '@/store'
import { CreatorButtons } from './CreatorButtons'
import { MeetingType } from '@namuho/types'
type ClassKeys = void | 'root' | 'options'

const useStyles = makeStyles<ClassKeys>()((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(2),
    },
    options: {
        flexDirection: 'column',
    },
}))

export interface DateObject {
    label: string
    value: MeetingType
}

export const DateType = () => {
    const { register, watch, getValues, setValue } = useForm<Date>()
    const chosenDateType = useSelector((state: RootState) => state.creator.chosenDateType)
    const { classes } = useStyles()
    const dispatch = useDispatch()

    const dateTypes: DateObject[] = [
        {
            label: 'Freundschaftlich',
            value: MeetingType.Friendly,
        },
        {
            label: 'Romantisch',
            value: MeetingType.Romantic,
        },
        {
            label: 'Gruppentreffen (freundschaftliches Treffen mit 3 bis 5 Menschen)',
            value: MeetingType.Group,
        },
    ]

    useEffect(() => {
        const subscription = watch((value) => {
            const { type } = value
            if (type) {
                dispatch(setChosenDateType(type))
            }
        })
        return () => subscription.unsubscribe()
    }, [watch, dispatch])

    useEffect(() => {
        const formType = getValues()
        const stateType = chosenDateType
        if (formType.type === stateType) return
        setValue('type', stateType)
    }, [getValues, setValue, chosenDateType])

    const handleNext = () => {
        dispatch(markStepCompletedByName(CreatorSteps.DATE_TYPE))
        dispatch(nextStep())
    }

    return (
        <StepContent
            content={
                <form className={classes.root}>
                    <RadioInput
                        className={classes.options}
                        label="Welche Art von Treffen möchtest du?"
                        options={dateTypes.map((type) => ({
                            label: type.label,
                            value: type.value,
                        }))}
                        {...register('type')}
                    />
                </form>
            }
            buttons={
                <CreatorButtons
                    nextStepActive={true}
                    previousStepActive={true}
                    isFirst={false}
                    isLast={false}
                    skipActive={true}
                    handleNext={handleNext}
                />
            }
        />
    )
}
