import { useAuth } from '@/contexts'
import { useQuery } from '@tanstack/react-query'

export const useUser = () => {
    const baseUrl = 'https://api.namuho.com/users/'

    const { session, user } = useAuth()
    const userData = useQuery({
        queryKey: [user?.id],
        queryFn: async () => {
            const response = await fetch(`${baseUrl}${user?.id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${session?.getAccessToken().getJwtToken()}`,
                },
            })

            if (!response.ok) {
                throw new Error(response.statusText)
            }

            return response.json()
        },
        enabled: !!user?.id && !!session,
    })

    return {
        isLoading: userData.isLoading,
        userData: userData.data,
    }
}
