import { Accordion, AccordionDetails, AccordionSummary, Container, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { PersonalDataEdit } from './PersonalDataEdit'
import { PersonalDetailsDashboard } from './PersonalDetailsDashboard'

export function PersonalData() {
    return (
        <Container id="personalData">
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="personalDataEdit" id="personalDataEdit-header">
                    <Typography variant="h1">Persönliche Daten</Typography>
                </AccordionSummary>
                <AccordionDetails id="personalDataEdit">
                    <PersonalDataEdit />
                    <PersonalDetailsDashboard />
                </AccordionDetails>
            </Accordion>
        </Container>
    )
}
