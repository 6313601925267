import { forwardRef, InputHTMLAttributes } from 'react'
import { makeStyles } from 'tss-react/mui'

export interface BirthDayInputProps extends InputHTMLAttributes<HTMLElement> {
    name: string
    label?: string
    error?: string
}

type ClassKeys = void | 'root' | 'input' | 'label'

const useStyles = makeStyles<ClassKeys>()((theme) => ({
    root: {
        display: 'grid',
        gridTemplateColumns: '1fr 3fr',
        alignItems: 'center',
        gap: theme.spacing(2),
        width: '100%',
        margin: 0,
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: theme.spacing(2),
            gap: theme.spacing(1),
        },
    },
    label: {
        width: '100%',
        display: 'block',
        textAlign: 'left',
    },
    input: {
        border: 'none',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        flexGrow: 2,
        width: '100%',
        colorScheme: 'dark',
        '&:focus': {
            borderRadius: 0,
        },
        '&:disabled': {
            color: theme.palette.common.black,
            backgroundColor: theme.palette.secondary.main,
        },
    },
}))

export const BirthdayInput = forwardRef<HTMLInputElement, BirthDayInputProps>((props: BirthDayInputProps, ref) => {
    const { name, label, error, ...rest } = props
    const { classes } = useStyles()
    return (
        <label className={classes.root} htmlFor={name}>
            {label && <span className={classes.label}>{label}</span>}
            <input className={classes.input} type="date" min="1900-01-01" max="2021-12-31" name={name} {...rest} ref={ref} />
            {error && <span>{error}</span>}
        </label>
    )
})

BirthdayInput.displayName = 'BirthdayInput'
