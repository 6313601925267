import { Paper, Typography } from '@mui/material'
import { ErrorBoundary } from 'react-error-boundary'
import { makeStyles } from 'tss-react/mui'
import * as Sentry from '@sentry/react'

interface CatchErrorProps {
    children: React.ReactNode
    section: string
}

const useStyles = makeStyles()((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        padding: theme.spacing(2),
        backgroundColor: theme.palette.common.white,
    },
}))
export function CatchError(props: CatchErrorProps) {
    const { children, section } = props
    const { classes } = useStyles()
    function fallbackRender(props: { error: any }) {
        const { error } = props
        Sentry.captureException(error, {
            tags: {
                section,
            },
            extra: {
                boundary: true,
            },
        })

        return (
            <Paper className={classes.root}>
                <Typography variant="h1" component="h1">
                    Oh Nein, ein Fehler ist aufgetreten!
                </Typography>
                <Typography variant="body1">
                    Es wurde bereits berichtet. Bitte gehen Sie zurück{' '}
                    <a className="inline-link" href="/">
                        zur Hauptseite
                    </a>{' '}
                    und versuchen Sie es erneut.
                </Typography>
            </Paper>
        )
    }
    return <ErrorBoundary fallbackRender={fallbackRender}>{children}</ErrorBoundary>
}
