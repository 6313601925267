export const isEmptyAnswer = (answer) => {
    if (typeof answer === 'string') {
        return answer.length === 0;
    }
    if (typeof answer === 'undefined') {
        return true;
    }
    if (answer === null) {
        return true;
    }
    if (Array.isArray(answer)) {
        return false;
    }
    return false;
};
